import { React } from "react";
import { useHistory } from "react-router-dom";

import "./ErrorPage.css";

const ErrorPage = () => {
  const history = useHistory();

  return (
    <div className="al-body">
      <div className="al-error-page-wrapper">
        <div style={{ width: "100%" }}>
          <div className="heading">Oops!</div>
          <div className="message">
            The page you are looking for is not found.
          </div>
          <div className="button-row">
            <button
              onClick={() => {
                history.goBack();
              }}
            >
              GO BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
