import { React, useEffect, useState } from "react";
import iconBell from "../../img/icons/ic_bell_blue.png";
import iconMail from "../../img/icons/ic_mail_blue.png";
import NotificationItem from "../NotificationItem/NotificationItem";
import OutlineButton from "../OutlineButton/OutlineButton";
import Loader from "../Loader/Loader";
import { useHistory } from "react-router-dom";
import { useSession } from "react-session-provider";
import "./Aside.css";
import {
  getUserNotificationsApi,
  updateNotificationApi,
} from "../../static/ApiClient/NotificationApi";
import { signOutApi } from "../../static/ApiClient/UsersApi";

const Aside = ({ expandNotifications }) => {
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [notifications, setNotifcations] = useState([]);
  const [isErrorNotfications, setIsErrorNotifications] = useState(false);

  const history = useHistory();
  const { store = {}, setKey, deleteKey } = useSession();
  const { sessionUser, sessionUserToken } = store;

  //useEffects
  useEffect(() => {
    getNotifications();
    // setInterval(() => {
    //   console.log("***** refreshing notifications...");
    //   getNotificationsSilent();
    // }, 60000);
  }, [sessionUser]);

  //api calls
  const getNotifications = async () => {
    if (sessionUser) {
      setIsLoadingNotifications(true);

      const apiRes = await getUserNotificationsApi({
        token: sessionUserToken,
        data: {
          ID: sessionUser.ID,
          ROLEID: sessionUser.ROLEID,
        },
      });

      switch (apiRes.message) {
        case "success":
          setIsErrorNotifications(false);
          var tmp = [];
          for (var item of apiRes.data) {
            if (item.READFLAG === 0) tmp.push(item);
          }
          setNotifcations(tmp);
          break;
        case "session expired":
          const apiRes1 = await signOutApi(sessionUserToken);
          if (apiRes1.message === "success") {
            console.log("signout successful");
          } else {
            console.log(apiRes1.message);
          }
          //clear session
          deleteKey("sessionUser");
          deleteKey("sessionUserToken");
          history.push("/");
          break;
        case "error":
          setIsErrorNotifications(true);
          break;
        default:
          setIsErrorNotifications(true);
      }

      setIsLoadingNotifications(false);
    }
  };

  const getNotificationsSilent = async () => {
    if (sessionUser) {
      const apiRes = await getUserNotificationsApi({
        token: sessionUserToken,
        data: {
          ID: sessionUser.ID,
          ROLEID: sessionUser.ROLEID,
        },
      });

      switch (apiRes.message) {
        case "success":
          setIsErrorNotifications(false);
          var tmp = [];
          for (var item of apiRes.data) {
            if (item.READFLAG === 0) tmp.push(item);
          }
          setNotifcations(tmp);

          break;
        case "error":
          setIsErrorNotifications(true);
          break;
        default:
          setIsErrorNotifications(true);
      }
    }
  };

  const dismissNotification = async (index) => {
    var notificationToDismiss = notifications[index];

    var array = [...notifications];

    if (index !== -1) {
      array.splice(index, 1);
      setNotifcations(array);
    }

    updateNotificationApi({
      token: sessionUserToken,
      data: {
        NOTIFICATIONID: notificationToDismiss.NOTIFICATIONID,
        READFLAG: 1,
      },
    });
  };

  //view builders

  const BuildListNotifications = () => {
    if (!isLoadingNotifications) {
      if (!isErrorNotfications) {
        if (notifications.length > 0) {
          return (
            <div className="list">
              {notifications.map((notification, index) => (
                <NotificationItem
                  notification={notification}
                  theme={index % 2 === 0 ? "darker" : ""}
                  dismiss={() => {
                    dismissNotification(index);
                  }}
                />
              ))}
              <div
                className="al-notification-item-see-all"
                onClick={expandNotifications}
              >
                SEE ALL
              </div>
            </div>
          );
        } else {
          return (
            <div className="list-empty al-muted-text">
              <div className="flex-column">
                <div className="al-muted-text">No new notifications</div>
                <div className="al-mt-20"></div>
                <div
                  className="al-notification-item-see-all"
                  onClick={expandNotifications}
                >
                  SEE ALL NOTIFICATIONS
                </div>
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className="list-empty">
            <div className="flex-column">
              <div className="al-muted-text">Failed to load notifications</div>
              <OutlineButton
                color="blue"
                text="Reload"
                onClick={() => {
                  getNotifications();
                }}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="list-empty">
          <Loader size="60" />
        </div>
      );
    }
  };

  const BuildNotificationsCard = () => {
    return (
      <div className="al-notifications">
        <div className="header">
          <div className="icon">
            <img src={iconBell} alt="N" />
          </div>
          <div className="title">Notifications</div>
        </div>
        {BuildListNotifications()}
      </div>
    );
  };

  const BuildMessagesCard = () => {
    return (
      <div className="al-messages">
        <div className="header">
          <div className="icon">
            <img src={iconMail} alt="N" />
          </div>
          <div className="title">Messages</div>
        </div>
        <div className="list-empty">
          <div className="al-muted-text">No messages</div>
          {/* <div
            className="al-notification-item-see-all"
            onClick={expandNotifications}
          >
            EXPAND
          </div> */}
        </div>
      </div>
    );
  };

  return (
    <div className="al-aside">
      {BuildNotificationsCard()}

      {BuildMessagesCard()}
    </div>
  );
};

export default Aside;
