import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import FormButton from "../../components/FormButton/FormButton";
import FormInput from "../../components/Inputs/FormInput/FormInput";
import {
  updateUserApi,
  resetPasswordByTokenApi,
  updateUserPasswordApi,
} from "../../static/ApiClient/UsersApi";
import AlertDialog from "../../components/dialogs/AlertDialog/AlertDialog";

import "./ChangePassword.css";
import Loader from "../../components/Loader/Loader";
import {
  validateFields,
  validatePassword,
} from "../../static/Validator/validator";

const ChangePassword = () => {
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);
  const [alertDialogVariation, setAlertDialogVariation] = useState("confirm");
  const [alertDialogType, setAlertDialogType] = useState("warn");
  const [alertDialogMessage, setAlertDialogMessage] = useState("");
  const [alertDialogPosBtnText, setAlertDialogPosBtnText] = useState("");
  const [alertDialogNegBtnText, setAlertDialogNegBtnText] = useState("");
  const [isFixedAlertDialog, setIsFixedAlertDialog] = useState(false);

  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");

  const [user, setUser] = useState(null);

  let { tokenKey } = useParams();
  const history = useHistory();

  useEffect(() => {
    checkTokenValidity();
  }, [tokenKey]);

  //api calls
  const checkTokenValidity = async () => {
    setIsLoadingPage(true);
    if (tokenKey) {
      const res = await resetPasswordByTokenApi({ TOKEN: tokenKey });

      switch (res.message) {
        case "success":
          setIsTokenInvalid(false);
          setUser(res.data);
          break;
        case "expired":
          setIsTokenInvalid(true);
          setErrorMessage("The password reset link has expired!");
          break;
        case "invalid":
          setIsTokenInvalid(true);
          setErrorMessage("Invalid request made");
          break;
        default:
          setIsTokenInvalid(true);
          setErrorMessage("Something went wrong, try again later");
      }
      setIsLoadingPage(false);
    }
  };

  const changePassword = async () => {
    setIsProcessing(true);

    let obj = {
      ID:user.ID,
      PASSWORD:pass1,
    }

    const res = await updateUserPasswordApi(obj);

    switch (res.message) {
      case "success":
        setIsPasswordReset(true);
        showAlertDialog({
          msg: "Your password has been reset successfully",
          type: "success",
          posText: "Go To Sign In",
          variation: "alert",
          isFixed: true,
        });
        break;
      case "error":
        showAlertDialog({
          msg: "An error occurred, try again later",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
        showAlertDialog({
          msg: res.message,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    setIsProcessing(false);
  };

  //show dialog
  const showAlertDialog = ({
    msg = "",
    type = "",
    posText = "",
    negText = "",
    variation = "",
    isFixed = false,
  }) => {
    setAlertDialogMessage(msg);
    setAlertDialogNegBtnText(negText);
    setAlertDialogPosBtnText(posText);
    setAlertDialogType(type);
    setAlertDialogVariation(variation);
    setIsFixedAlertDialog(isFixed);
    setIsOpenAlertDialog(true);
  };

  const validateChangePass = () => {
    if (validateFields([pass1])) {
      if (validateFields([pass2])) {
        if (pass1 === pass2) {
          if (validatePassword(pass1)) {
            changePassword();
          } else
            showAlertDialog({
              msg: "Please provide a valid password. Your password should be at least 6 charactes long",
              type: "error",
              posText: "Ok",
              variation: "alert",
            });
        } else
          showAlertDialog({
            msg: "Your passwords do not match",
            type: "error",
            posText: "Ok",
            variation: "alert",
          });
      } else
        showAlertDialog({
          msg: "Confirm new passowrd",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    } else
      showAlertDialog({
        msg: "Provide new passowrd",
        type: "error",
        posText: "Ok",
        variation: "alert",
      });
  };

  //view builders
  const BuildContent = () => {
    if (!isLoadingPage) {
      if (!isTokenInvalid) {
        return BuildChangePasswordForm();
      } else {
        return BuildInvalidRequest();
      }
    } else
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>Just a moment ...</div>
          <div className="al-mt-20">
            <Loader size="60" />
          </div>
        </div>
      );
  };

  const BuildInvalidRequest = () => {
    return (
      <>
        <div className="al-error-large">Error!</div>
        <div className="al-error-desc">{errorMessage}</div>
      </>
    );
  };

  const BuildChangePasswordForm = () => {
    return (
      <>
        <div className="al-header">Create Your Password</div>
        <div className="al-container-100">
          <FormInput
            placeholder="New Password"
            type="password"
            label="Password*"
            name="password1"
            value={pass1}
            onChange={(e) => {
              setPass1(e.target.value);
            }}
          />
        </div>
        <div className="al-container-100">
          <FormInput
            placeholder="Confirm Password"
            type="password"
            label="Confirm Password*"
            name="password2"
            value={pass2}
            onChange={(e) => {
              setPass2(e.target.value);
            }}
          />
        </div>

        <div className="al-container-100">
          <FormButton
            text="Change"
            onClick={validateChangePass}
            isProcessing={isProcessing}
          />
        </div>
      </>
    );
  };

  return (
    <div className="al-body-wrap">
      <div className="al-centered">
        <div className="al-change-password">{BuildContent()}</div>
      </div>
      <AlertDialog
        type={alertDialogType}
        variation={alertDialogVariation}
        message={alertDialogMessage}
        isOpen={isOpenAlertDialog}
        isFixed={isFixedAlertDialog}
        onClose={() => {
          if (isPasswordReset) history.push("/");
          setIsOpenAlertDialog(false);
        }}
        positiveButtonText={alertDialogPosBtnText}
        negativeButtonText={alertDialogNegBtnText}
      />
    </div>
  );
};

export default ChangePassword;
