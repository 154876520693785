import axios from "axios";
import { BACKEND_URL } from "../../Config";
const _apiKey = require("../apiKey.json")?.apiKey;

export const getBanksApi = async () => {
  const headers = {
    Authorization: "Bearer my-token",
    apiKey: _apiKey,
  };

  var res = await axios
    .get(BACKEND_URL + "banks", { headers: headers })
    .catch((e) => {
      console.log("***** get banks api error *****", e);
    })
    .catch((e) => {
      console.log("***** get banks api error *****", e);
    });
  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) return res.data;
    else {
      console.log(
        "***** get banks api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};
