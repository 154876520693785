import { React, useState, useEffect } from "react";

import Aside from "../../components/Aside/Aside";
import NotificationDialog from "../../components/dialogs/NotificationDialog/NotificationDialog";
import FormButton from "../../components/FormButton/FormButton";
import FormInput from "../../components/Inputs/FormInput/FormInput";
import Loader from "../../components/Loader/Loader";
import SideNav from "../../components/SideNav/SideNav";
import { useSession } from "react-session-provider";
import { useHistory } from "react-router-dom";
import iconAvatar from "../../img/icons/ic_avatar.png";
import {
  validateAddress,
  validateFields,
  validatePassword,
} from "../../static/Validator/validator";
import AlertDialog from "../../components/dialogs/AlertDialog/AlertDialog";
// import Tooltip from "react-simple-tooltip";
import "./Profile.css";
import {
  changeUserPasswordApi,
  getUserInfoApi,
  updateUserApi,
} from "../../static/ApiClient/UsersApi";

const Profile = () => {
  const [isOPenNotification, setIsOpenNotification] = useState(false);
  const [activeTab, setActiveTab] = useState("profileInfo");
  const [isGettingUserInfo, setIsGettingUserInfo] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);
  const [alertDialogVariation, setAlertDialogVariation] = useState("confirm");
  const [alertDialogType, setAlertDialogType] = useState("warn");
  const [alertDialogMessage, setAlertDialogMessage] = useState("");
  const [alertDialogPosBtnText, setAlertDialogPosBtnText] = useState("");
  const [alertDialogNegBtnText, setAlertDialogNegBtnText] = useState("");
  const [isFixedAlertDialog, setIsFixedAlertDialog] = useState(false);

  const [address, setAddress] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [isUpdatingProfile, setpdatingProfile] = useState(false);
  const [userProfileInfo, setUserProfileInfo] = useState(null);

  const { store = {}, setKey } = useSession();
  const { sessionUser, sessionUserToken } = store;
  const history = useHistory();

  //use effects
  useEffect(() => {
    getUserInfo();
  }, []);

  //page security
  useEffect(() => {
    if (sessionUser) {
      // if (sessionUser.ROLEID === 1 || sessionUser.ROLEID === 2) {
      //   console.log("session ok");
      // } else {
      //   history.push("/error");
      // }
    } else {
      history.push("/");
    }
  }, []);

  //api calls
  const changePassword = async () => {
    setIsChangingPassword(true);

    let obj = {
      USERID: sessionUser.ID,
      CURRENTPASSWORD: currentPassword,
      NEWPASSWORD: password1,
    };

    const apiRes = await changeUserPasswordApi({
      token: sessionUserToken,
      data: obj,
    });

    switch (apiRes.message) {
      case "success":
        setPassword1("");
        setPassword2("");
        setCurrentPassword("");

        showAlertDialog({
          msg: "Password changed successfully",
          type: "success",
          posText: "Ok",
          variation: "alert",
        });
        break;
      case "no match":
        showAlertDialog({
          msg: "Incorrect current password provided",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      case "error":
        showAlertDialog({
          msg: "An error occurred, try again later",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
        showAlertDialog({
          msg: apiRes.message,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    setIsChangingPassword(false);
  };

  const updateUserInfo = async () => {
    setIsGettingUserInfo(true);

    var obj = {
      ACCOUNTNUMBER: accountNumber,
      PHONENUMBER: phone,
      ADDRESS: address,
      ID: sessionUser.ID,
    };

    const apiRes = await updateUserApi(obj);

    switch (apiRes.message) {
      case "success":
        showAlertDialog({
          msg: "Info updated successfully",
          type: "success",
          posText: "Ok",
          variation: "alert",
        });
        break;
      case "error":
        showAlertDialog({
          msg: "An error occurred",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
        showAlertDialog({
          msg: apiRes.message,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    setIsGettingUserInfo(false);
  };

  const getUserInfo = async () => {
    setIsGettingUserInfo(true);

    const apiRes = await getUserInfoApi({
      token: sessionUserToken,
      id: sessionUser.ID,
    });

    switch (apiRes.message) {
      case "success":
        setUserProfileInfo(apiRes.data);
        setAccountNumber(apiRes.data.ACCOUNTNUMBER);
        setPhone(apiRes.data.PHONENUMBER);
        setAddress(apiRes.data.ADDRESS);
        break;
      case "error":
        showAlertDialog({
          msg: "An error occurred",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
        showAlertDialog({
          msg: apiRes.message,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    setIsGettingUserInfo(false);
  };

  //other methods
  const validateChangePassword = () => {
    if (validateFields([currentPassword])) {
      if (validateFields([password1])) {
        if (validatePassword(password1)) {
          if (validateFields([password2])) {
            if (password2 === password1) {
              changePassword();
            } else
              showAlertDialog({
                msg: "Passwords do not math",
                type: "error",
                posText: "Ok",
                variation: "alert",
              });
          } else
            showAlertDialog({
              msg: "Confirm your new password",
              type: "error",
              posText: "Ok",
              variation: "alert",
            });
        } else
          showAlertDialog({
            msg: "Please provide a valid password. Your password should be at least 6 charactes long",
            type: "error",
            posText: "Ok",
            variation: "alert",
          });
      } else
        showAlertDialog({
          msg: "Provide a new password",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    } else
      showAlertDialog({
        msg: "Please provide a valid password. Your password should be at least 6 charactes long",
        type: "error",
        posText: "Ok",
        variation: "alert",
      });
  };

  const validateUpdateProfile = () => {
    if (validateFields([address])) {
      if (validateAddress(address)) {
        updateUserInfo();
      } else
        showAlertDialog({
          msg: "Please provide a valid address including your country.Example, Kinondoni Dar es salaam Tanzania",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    } else
      showAlertDialog({
        msg: "Provide your addres",
        type: "error",
        posText: "Ok",
        variation: "alert",
      });
  };

  //show dialog
  const showAlertDialog = ({
    msg = "",
    type = "",
    posText = "",
    negText = "",
    variation = "",
    isFixed = false,
  }) => {
    setAlertDialogMessage(msg);
    setAlertDialogNegBtnText(negText);
    setAlertDialogPosBtnText(posText);
    setAlertDialogType(type);
    setAlertDialogVariation(variation);
    setIsFixedAlertDialog(isFixed);
    setIsOpenAlertDialog(true);
  };

  //view builders

  const BuildTabs = () => {
    return (
      <div className="tabs">
        <div
          className={"tab " + (activeTab === "profileInfo" ? "active-tab" : "")}
          onClick={() => {
            setActiveTab("profileInfo");
          }}
        >
          Personal Information
        </div>
        <div
          className={
            "tab " + (activeTab === "changePassword" ? "active-tab" : "")
          }
          onClick={() => {
            setActiveTab("changePassword");
          }}
        >
          Change Password
        </div>
      </div>
    );
  };

  const BuildTabViews = () => {
    switch (activeTab) {
      case "profileInfo":
        return BuildProfileInfoView();
      case "changePassword":
        return BuildPasswordChangeView();
      default:
        return <></>;
    }
  };

  const BuildProfileInfoView = () => {
    if (!isGettingUserInfo) {
      if (userProfileInfo) {
        return (
          <div className="list">
            <div className="avatar-wrapper">
              <div className="avatar">
                <img src={iconAvatar} alt="</>" />
              </div>
            </div>

            <div className="flex-container">
              <div className="al-container-50 padded">
                <div className="al-display-input">
                  <div className="label">First Name</div>
                  <div className="value">{userProfileInfo.FIRSTNAME}</div>
                </div>
              </div>
              <div className="al-container-50 padded">
                <div className="al-display-input">
                  <div className="label">Middle Name</div>
                  <div className="value">{userProfileInfo.MIDDLENAME}</div>
                </div>
              </div>
              <div className="al-container-50 padded">
                <div className="al-display-input">
                  <div className="label">Last Name</div>
                  <div className="value">{userProfileInfo.LASTNAME}</div>
                </div>
              </div>
              <div className="al-container-50 padded">
                <div className="al-display-input">
                  <div className="label">Email</div>
                  <div className="value">{userProfileInfo.EMAIL}</div>
                </div>
              </div>
              <div className="al-container-50 padded">
                <div className="al-display-input">
                  <div className="label">Bank</div>
                  <div className="value">{userProfileInfo.BANKNAME}</div>
                </div>
              </div>
              <div className="al-container-50 padded">
                <div className="al-display-input">
                  <div className="label">I am</div>
                  <div className="value">{userProfileInfo.CATEGORYNAME}</div>
                </div>
              </div>

              <div className="al-container-100 padded">
                <h5 className="al-mt-10">Update my info</h5>
              </div>

              <div className="al-container-50 padded">
                <FormInput
                  type="text"
                  label="Account Number"
                  value={accountNumber}
                  name="ACCOUNTNUMBER"
                  onChange={(e) => {
                    setAccountNumber(e.target.value);
                  }}
                />
              </div>
              <div className="al-container-50 padded">
                <FormInput
                  type="text"
                  label="Physical Address*"
                  placeholder="Street, region, country"
                  value={address}
                  name="ADDRESS"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div>
              <div className="al-container-50 padded">
                <FormInput
                  type="text"
                  label="Phone Number"
                  placeholder="eg +255 000 000 000"
                  value={phone}
                  name="PHONE"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
              <div className="al-container-50 padded"></div>
              <div className="al-button-row">
                <div className="al-container-50 padded">
                  <FormButton
                    text="UPDATE"
                    isProcessing={isUpdatingProfile}
                    onClick={validateUpdateProfile}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      } else return <></>;
    } else {
      return (
        <div className="list-empty">
          <Loader size="60" />
        </div>
      );
    }
  };

  const BuildPasswordChangeView = () => {
    return (
      <div className="list">
        <div className="flex-container">
          <div className="al-container-50 padded">
            <FormInput
              type="password"
              label="Current password"
              placeholder="Current password"
              name="CURRENTPASSWORD"
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
          </div>
          <div className="al-container-50"></div>
          <div className="al-container-50 padded">
            <FormInput
              type="password"
              label="New password"
              placeholder="Current password"
              name="NEWPASSWORD1"
              value={password1}
              onChange={(e) => {
                setPassword1(e.target.value);
              }}
            />
          </div>
          <div className="al-container-50 "></div>
          <div className="al-container-50 padded">
            <FormInput
              type="password"
              label="Confirm password"
              placeholder="Re-type new password"
              name="NEWPASSWORD2"
              value={password2}
              onChange={(e) => {
                setPassword2(e.target.value);
              }}
            />
          </div>
          <div className="al-container-50"></div>
          <div className="al-container-50 padded">
            <FormButton
              text="Change"
              isProcessing={isChangingPassword}
              onClick={validateChangePassword}
            />
          </div>
        </div>
      </div>
    );
  };

  const BuildContent = () => {
    return (
      <div className="al-profile-tab-view">
        {BuildTabs()}
        {BuildTabViews()}
      </div>
    );
  };
  return (
    <div className="al-body">
      <SideNav activePage="profile" />
      <div className="al-main">
        <h1>My Profile</h1>
        {BuildContent()}
      </div>
      <Aside
        expandNotifications={() => {
          setIsOpenNotification(true);
        }}
      />

      <NotificationDialog
        onClose={() => {
          setIsOpenNotification(false);
        }}
        isOpen={isOPenNotification}
      />

      <AlertDialog
        type={alertDialogType}
        variation={alertDialogVariation}
        message={alertDialogMessage}
        isOpen={isOpenAlertDialog}
        isFixed={isFixedAlertDialog}
        onClose={() => {
          setIsOpenAlertDialog(false);
        }}
        positiveButtonText={alertDialogPosBtnText}
        negativeButtonText={alertDialogNegBtnText}
      />
    </div>
  );
};

export default Profile;
