import React from "react";
import iconYellow from "../../../img/icons/ic_info_yellow.png";
import iconRed from "../../../img/icons/ic_info_red.png";
import iconGreen from "../../../img/icons/ic_info_green.png";

import "./AlertDialog.css";
import FormInput from "../../Inputs/FormInput/FormInput";

/* Use alert dialog with these states */

// const [isOpenAlertDialog,setIsOpenAlertDialog] = useState("false");
// const [alertDialogVariation, setAlertDialogVariation] = useState("confirm");
// const [alertDialogType, setAlertDialogType] = useState("warn");
// const [alertDialogMessage, setAlertDialogMessage] = useState("");
// const [alertDialogPosBtnText, setAlertDialogPosBtnText] = useState("");
// const [alertDialogNegBtnText, setAlertDialogNegBtnText] = useState("");

/* methods to show dialog*/

// const setShowAlertDialog = ({ msg="", type="", posText="", negText="", variation="" }) => {
//   setAlertDialogMessage(msg);
//   setAlertDialogNegBtnText(negText);
//   setAlertDialogPosBtnText(posText);
//   setAlertDialogType(type);
//   setAlertDialogVariation(variation);
//   setIsOpenAlertDialog(true);
// };

const AlertDialog = ({
  isOpen,
  type,
  variation,
  onClose,
  positiveButtonMethod,
  message,
  negativeButtonText,
  positiveButtonText,
  isFixed = false,
  withTextField = false,
  onChangeTextField,
  textFieldLabel = "",
  textFieldPlaceholder = "",
  textFieldMaxLength = "500",
  textFieldValue = "",
}) => {
  const BuildButtons = () => {
    if (variation === "confirm")
      return (
        <div className="flex-row">
          <button onClick={positiveButtonMethod} className="dialog-btn-blue">
            {positiveButtonText}
          </button>
          <button onClick={onClose} className="dialog-btn-red">
            {negativeButtonText}
          </button>
        </div>
      );
    else if (variation === "alert")
      return (
        <div className="flex-row">
          <button onClick={onClose} className="dialog-btn-blue">
            {positiveButtonText}
          </button>
        </div>
      );
  };

  const BuildIcon = () => {
    switch (type) {
      case "success":
        return <img src={iconGreen} alt="i" />;
      case "error":
        return <img src={iconRed} alt="i" />;
      case "warn":
        return <img src={iconYellow} alt="i" />;
      default:
        return <img src={iconYellow} alt="i" />;
    }
  };

  const BuildTextField = () => {
    if (withTextField) {
      return (<div>
        <FormInput
          type = "textarea"
          label={textFieldLabel}
          onChange={onChangeTextField}
          maxLength={textFieldMaxLength}
          placeholder={textFieldPlaceholder}
          value = {textFieldValue}
        />
      </div>)
    } else return <></>
  }
  if (isOpen)
    return (
      <div className="al-dialog-full" style={{ zIndex: "11" }}>
        <div
          className="al-dialog-gesture-detector"
          onClick={!isFixed ? onClose : null}
        ></div>
        <div className="al-alert-dialog">
          <div className="icon">{BuildIcon()}</div>
          <div className="message">{message}</div>
          {BuildTextField()}
          {BuildButtons()}
        </div>
      </div>
    );
  else return <></>;
};

export default AlertDialog;
