import axios from "axios";
import { BACKEND_URL } from "../../Config";
const _apiKey = require("../apiKey.json")?.apiKey;

export const getUserNotificationsApi = async ({ token, data }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey: _apiKey,
  };

  var res = await axios
    .post(BACKEND_URL + `notifications/user`, data, { headers: headers })
    .catch((e) => {
      console.log("***** user notifications get api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200 && res.data.message !== "unauthorized") {
      console.log("***** user notifications get returned *****", res.data);
      return res.data;
    } else if (
      parseInt(res.status) === 200 &&
      res.data.message === "unauthorized"
    ) {
      console.log("***** session expired ");
      return { message: "session expired", data: {} };
    } else {
      console.log(
        "***** user notifications get api error with status code ***** " +
          res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const updateNotificationApi = async ({ token, data }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey: _apiKey,
  };

  var res = await axios
    .patch(BACKEND_URL + `notifications/${data.NOTIFICATIONID}`, data, {
      headers: headers,
    })
    .catch((e) => {
      console.log("***** notification update api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** notification update api returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** notification update api error with status code ***** " +
          res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};
