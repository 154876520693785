import axios from "axios";
import { BACKEND_URL } from "../../Config";
const _apiKey = require("../apiKey.json")?.apiKey;

// axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
// axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export const signUpApi = async (data) => {
  const headers = {
    Authorization: "Bearer my-token",
    apiKey: _apiKey,
  };

  var res = await axios
    .post(BACKEND_URL + `users/SignUp`, data, { headers: headers })
    .catch((e) => {
      console.log("***** user signup api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** user sign up returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** user signup api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const signInApi = async (data) => {
  const headers = {
    Authorization: "Bearer my-token",
    apiKey: _apiKey,
  };

  var res = await axios
    .post(BACKEND_URL + `users/SignIn`, data, { headers: headers })
    .catch((e) => {
      console.log("***** user signin api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      // console.log("***** user signin returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** user signin api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const getUserCategoriesApi = async (BankId) => {
  try {
    const headers = {
      Authorization: "Bearer my-token",
      apiKey: _apiKey,
    };

    var res = await axios
      .get(BACKEND_URL + `users/categories/${BankId}`, { headers: headers })
      .catch((e) => {
        console.log("***** get user categories api error *****", e);
      });

    if (typeof res !== "undefined") {
      if (parseInt(res.status) === 200) return res.data;
      else {
        console.log(
          "***** get user category api error with status code ***** " +
            res.status
        );
        return { message: "error " + res.status, data: {} };
      }
    } else return { message: "server error " + res.status, data: {} };
  } catch (err) {
    alert(JSON.stringify("An error occurred"));
    return { message: "unknown error ", data: err?.message || "" };
  }
};

export const getUserRolesApi = async ({ token }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey: _apiKey,
  };

  var res = await axios
    .get(BACKEND_URL + `users/roles/all`, { headers: headers })
    .catch((e) => {
      console.log("***** user roles api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** user roles returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** user role api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const updateUserApi = async (data) => {
  const headers = {
    Authorization: "Bearer my-token",
    apiKey: _apiKey,
  };

  var res = await axios
    .patch(BACKEND_URL + `users/${data.ID}`, data, { headers: headers })
    .catch((e) => {
      console.log("***** user update api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** user update returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** user update api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const updateUserSignUpStatusApi = async ({ data, token }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey: _apiKey,
  };

  var res = await axios
    .patch(BACKEND_URL + `users/user/signupstatus`, data, { headers: headers })
    .catch((e) => {
      console.log("***** user update signupstatus api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** user update signupstatus returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** user update signupstatus api error with status code ***** " +
          res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const updateUserPasswordApi = async (data) => {
  const headers = {
    Authorization: "Bearer my-token",
    "no-auth": "true",
    apiKey: _apiKey,
  };

  var res = await axios
    .patch(BACKEND_URL + `users/password/${data.ID}`, data, {
      headers: headers,
    })
    .catch((e) => {
      console.log("***** user update password api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** user update password returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** user update password api error with status code ***** " +
          res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const getUsersApi = async ({ token }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey: _apiKey,
  };

  console.log("session");

  var res = await axios
    .get(BACKEND_URL + `users`, {
      headers: headers,
    })
    .catch((e) => {
      console.log("***** user get api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200 && res.data.message !== "unauthorized") {
      console.log("***** user get returned *****", res.data);
      return res.data;
    } else if (
      parseInt(res.status) === 200 &&
      res.data.message === "unauthorized"
    ) {
      console.log("***** session expired ");
      return { message: "session expired", data: {} };
    } else {
      console.log(
        "***** user get api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const signOutApi = async (token) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey: _apiKey,
  };


  var res = await axios
    .post(
      BACKEND_URL + `users/user/signOut`,
      {
        userToken: token,
      },
      {
        headers: headers,
      }
    )
    .catch((e) => {
      console.log("***** user signout api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** user signout returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** user signout api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const getUserInfoApi = async ({ id, token }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey: _apiKey,
  };

  var res = await axios
    .get(BACKEND_URL + `users/${id}`, { headers: headers })
    .catch((e) => {
      console.log("***** user get api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** user get returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** user get api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const getUserByEmailApi = async (email) => {
  const headers = {
    Authorization: "Bearer my-token",
    apiKey: _apiKey,
  };

  const data = {
    EMAIL: email,
  };

  var res = await axios
    .post(BACKEND_URL + `users/user/byemail`, data, { headers: headers })
    .catch((e) => {
      console.log("***** user get by email api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** user get by email api returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** user get by email api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const resetPasswordByCodeApi = async (data) => {
  const headers = {
    Authorization: "Bearer my-token",
    apiKey: _apiKey,
  };

  let obj = {
    CODE: data.CODE,
    PASSWORD: data.PASSWORD,
    USERID: data.USERID,
    EMAIL: data.EMAIL,
  };

  var res = await axios
    .post(BACKEND_URL + `users/resetpassword`, obj, { headers: headers })
    .catch((e) => {
      console.log("***** user get by email api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** user get by email api returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** user get by email api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const resetPasswordByTokenApi = async (data) => {
  const headers = {
    Authorization: "Bearer my-token",
    apiKey: _apiKey,
  };

  var res = await axios
    .post(BACKEND_URL + `users/user/bypassresettoken`, data, {
      headers: headers,
    })
    .catch((e) => {
      console.log("***** user get by token api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** user get by token api returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** user get by token api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const addUserApi = async ({ data, token }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    sendVerificationEmail: "true",
    apiKey: _apiKey,
  };

  var res = await axios
    .post(BACKEND_URL + `users`, data, { headers: headers })
    .catch((e) => {
      console.log("***** user add api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** user add returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** user add api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const changeUserPasswordApi = async ({ token, data }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey: _apiKey,
  };

  var res = await axios
    .patch(BACKEND_URL + `users/user/changePassword`, data, {
      headers: headers,
    })
    .catch((e) => {
      console.log("***** Change password api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** Change password api returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** Change password api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};
