import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ForgetPasswordDialog from "../../components/dialogs/ForgetPasswordDialog/ForgetPasswordDialog";
import FormButton from "../../components/FormButton/FormButton";
import FormInput from "../../components/Inputs/FormInput/FormInput";
import logoImage from "../../img/dib-logo.png";
import AlertDialog from "../../components/dialogs/AlertDialog/AlertDialog";
import {
  validateFields,
  validateAddress,
  validateSelect,
  validatePassword,
  validateEmail,
} from "../../static/Validator/validator";
import "./SignInSignUp.css";
import axios from "axios";
import { BACKEND_URL } from "../../Config";
import {
  getUserCategoriesApi,
  signInApi,
  signOutApi,
  signUpApi,
} from "../../static/ApiClient/UsersApi";
import { getBanksApi } from "../../static/ApiClient/BanksApi";
import { useSession } from "react-session-provider";

const SignInSignUp = () => {
  const [activeTab, setActiveTab] = useState("signin");
  const [isOpenForgetPwdDialog, setIsOpenForgetPwdDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [userCategories, setUserCategories] = useState([]);
  const [banks, setBanks] = useState([]);

  const [signUpFormData, setSignUpFormData] = useState({
    FIRSTNAME: "",
    MIDDLENAME: "",
    LASTNAME: "",
    EMAIL: "",
    ADDRESS: "",
    ACCOUNTNUMBER: "",
    BANKID: "",
    USERCATEGORY: "",
  });
  const [isHiddenAccountNo, setIsHiddenAccountNo] = useState(true);
  const [isHiddenUserCategories, setIsHiddenUserCategories] = useState(true);

  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);
  const [alertDialogVariation, setAlertDialogVariation] = useState("confirm");
  const [alertDialogType, setAlertDialogType] = useState("warn");
  const [alertDialogMessage, setAlertDialogMessage] = useState("");
  const [alertDialogPosBtnText, setAlertDialogPosBtnText] = useState("");
  const [alertDialogNegBtnText, setAlertDialogNegBtnText] = useState("");
  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState(false);

  const [signInFormData, setSignInFormData] = useState({
    EMAIL: "",
    PASSWORD: "",
  });

  const history = useHistory();
  const { store = {}, setKey, deleteKey } = useSession();
  const { sessionUser, sessionUserToken } = store;

  //useEffects
  // useEffect(() => {
  //   if (signUpFormData.BANKID && signUpFormData.BANKID !== -1) {
  //     getUserCategories()
  //   } else setIsHiddenUserCategories(true)
  // }, [signUpFormData.BANKID])

  useEffect(() => {
    // getBanks()
    setSignUpFormData({
      ...signUpFormData,
      ["BANKID"]: 1,
    });
    getUserCategories();
  }, []);

  //api calls
  const signIn = async () => {
    setIsProcessing(true);

    const res = await signInApi(signInFormData);

    switch (res.message) {
      case "success":
        setKey("sessionUser", res.data.user);
        setKey("sessionUserToken", res.data.token);
        setTimeout(() => {
          setIsProcessing(false);
          history.push("/publications");
        }, 2000);

        //kill session after 20 minutes
        setTimeout(() => {
          handleSignOut();
        }, 1200000);

        break;
      case "error":
        showAlertDialog({
          msg: "An error occurred, try again later",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        setIsProcessing(false);
        break;
      case "inactive":
        showAlertDialog({
          msg: "User is inactive, please contact aministrator",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        setIsProcessing(false);
        break;
      case "no match":
        showAlertDialog({
          msg: "Incorrect email or password provided",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        setIsProcessing(false);
        break;
      default:
        showAlertDialog({
          msg: res.message,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        setIsProcessing(false);
    }
  };

  const handleSignOut = async () => {
    const apiRes = await signOutApi(sessionUserToken);
    if (apiRes.message === "success") {
      console.log("signout successful");
    } else {
      console.log(apiRes.message);
    }
    //clear session
    deleteKey("sessionUser");
    deleteKey("sessionUserToken");

    history.push("/");
  };

  const getUserCategories = async () => {
    const res = await getUserCategoriesApi(1);

    var temp = [];
    if (res.message === "success") {
      res.data.forEach((item, index) => {
        temp.push({ display: item.CATEGORYNAME, value: item.USERCATEGORYID });
      });

      setUserCategories(temp);

      console.log("User categories are", temp);
      setIsHiddenUserCategories(false);
    }
  };

  const getBanks = async () => {
    const res = await getBanksApi();
    console.log(res);

    if (res.message === "success") {
      var temp = [];

      res.data.forEach((bank, index) => {
        temp.push({ display: bank.BANKNAME, value: bank.BANKID });
      });
      console.log("banks array has", temp);
      setBanks(temp);
    }
  };

  const signUp = async () => {
    setIsProcessing(true);
    // alert(JSON.stringify(signUpFormData));
    const res = await signUpApi(signUpFormData);

    switch (res.message) {
      case "success":
        setIsSignUpSuccessful(true);
        showAlertDialog({
          msg: "Your registration has been sent to Deposit Insurance Board for verification. Our system admin will contact you via email or phone where necessary. Please coorperate on the verification process. You will receive an email of confirmation once your account has been activated",
          type: "success",
          posText: "Ok",
          variation: "alert",
        });
        break;
      case "error":
        showAlertDialog({
          msg: "An error occurred, try again later",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      case "email exists":
        showAlertDialog({
          msg: "A user with similar email already exists in the portal, if you already have an account please sign in or reset password if you have forgotten it",
          type: "warn",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
        showAlertDialog({
          msg: res.message,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    setIsProcessing(false);
  };

  const handleChangeSignUpFormData = (e) => {
    e.persist();
    if (e.target.name === "USERCATEGORY") {
      if (parseInt(e.target.value) === 2) {
        setIsHiddenAccountNo(false);
      } else setIsHiddenAccountNo(true);
    }
    setSignUpFormData({
      ...signUpFormData,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleChangeSigninFormData = (e) => {
    e.persist();

    setSignInFormData({
      ...signInFormData,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  //validators
  const validateSignUp = () => {
    const mandatoryFields = [
      signUpFormData.FIRSTNAME ?? "",
      signUpFormData.MIDDLENAME ?? "",
      signUpFormData.LASTNAME ?? "",
      signUpFormData.EMAIL ?? "",
      signUpFormData.ADDRESS ?? "",
      signUpFormData.BANKID ?? "",
      signUpFormData.USERCATEGORY ?? "",
    ];

    if (validateFields(mandatoryFields)) {
      if (validateEmail(signUpFormData.EMAIL)) {
        if (validateAddress(signUpFormData.ADDRESS)) {
          if (validateSelect(signUpFormData.BANKID)) {
            if (validateSelect(signUpFormData.USERCATEGORY)) {
              if (
                (parseInt(signUpFormData.USERCATEGORY) === 2 &&
                  validateFields([signUpFormData.ACCOUNTNUMBER ?? ""])) ||
                parseInt(signUpFormData.USERCATEGORY) !== 2
              ) {
                signUp();
              } else {
                showAlertDialog({
                  msg: "Please provide a valid account number",
                  type: "error",
                  posText: "Ok",
                  variation: "alert",
                });
              }
            } else
              showAlertDialog({
                msg: 'Pease choose one from "Who are you?", we need to know your role with the bank ',
                type: "error",
                posText: "Ok",
                variation: "alert",
              });
          } else
            showAlertDialog({
              msg: "Please select your bank",
              type: "error",
              posText: "Ok",
              variation: "alert",
            });
        } else
          showAlertDialog({
            msg: "Please provide a valid address including your country.Example, Kinondoni Dar es salaam Tanzania",
            type: "error",
            posText: "Ok",
            variation: "alert",
          });
      } else
        showAlertDialog({
          msg: "Invalid email provided",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    } else
      showAlertDialog({
        msg: "Provide all mandatory fields (With *)",
        type: "error",
        posText: "Ok",
        variation: "alert",
      });
  };

  const validateSignIn = () => {
    if (validateFields([signInFormData.EMAIL ?? ""])) {
      if (validateEmail(signInFormData.EMAIL ?? "")) {
        if (validatePassword(signInFormData.PASSWORD ?? "")) {
          signIn();
        } else
          showAlertDialog({
            msg: "Please provide a valid password. Your password should be at least 6 charactes long",
            type: "error",
            posText: "Ok",
            variation: "alert",
          });
      } else
        showAlertDialog({
          msg: "Please provide a valid email address",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    } else
      showAlertDialog({
        msg: "Provide email",
        type: "error",
        posText: "Ok",
        variation: "alert",
      });
  };

  //show dialog
  const showAlertDialog = ({
    msg = "",
    type = "",
    posText = "",
    negText = "",
    variation = "",
  }) => {
    setAlertDialogMessage(msg);
    setAlertDialogNegBtnText(negText);
    setAlertDialogPosBtnText(posText);
    setAlertDialogType(type);
    setAlertDialogVariation(variation);
    setIsOpenAlertDialog(true);
  };

  //view builders

  const BuildSignInTabView = () => {
    return (
      <div className="al-form">
        <div className="al-logo">
          <img src={logoImage} alt="DIB" />
        </div>

        <FormInput
          label="Email*"
          name="EMAIL"
          placeholder="email@example.com"
          type="email"
          value={signInFormData.EMAIL}
          onChange={(e) => handleChangeSigninFormData(e)}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              validateSignIn();
            }
          }}
        />

        <FormInput
          label="Password*"
          name="PASSWORD"
          placeholder="Enter password"
          type="password"
          value={signInFormData.password}
          onChange={(e) => handleChangeSigninFormData(e)}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              validateSignIn();
            }
          }}
        />

        <FormButton
          text="SIGN IN"
          onClick={validateSignIn}
          isProcessing={isProcessing}
        />

        <div id="forgot-password" className="al-mt-10">
          <div className="txt">
            Forgot Password? Reset{" "}
            <span
              onClick={() => {
                if (!isProcessing) setIsOpenForgetPwdDialog(true);
              }}
            >
              here
            </span>
          </div>
        </div>
      </div>
    );
  };

  const BuildSignUpTabView = () => {
    return (
      <div className="al-form">
        <div className="al-logo">
          <img src={logoImage} alt="DIB" />
        </div>
        <FormInput
          name="FIRSTNAME"
          type="text"
          label="First Name*"
          placeholder=""
          onChange={(e) => {
            handleChangeSignUpFormData(e);
          }}
          value={signUpFormData.FIRSTNAME}
        />
        <FormInput
          name="MIDDLENAME"
          type="text"
          label="Middle Name*"
          placeholder=""
          onChange={(e) => {
            handleChangeSignUpFormData(e);
          }}
          value={signUpFormData.MIDDLENAME}
        />
        <FormInput
          name="LASTNAME"
          type="text"
          label="Last Name*"
          placeholder=""
          onChange={(e) => {
            handleChangeSignUpFormData(e);
          }}
          value={signUpFormData.LASTNAME}
        />
        <FormInput
          name="EMAIL"
          type="email"
          label="Email*"
          placeholder="email@example.com"
          onChange={(e) => {
            handleChangeSignUpFormData(e);
          }}
          value={signUpFormData.EMAIL}
        />
        <FormInput
          name="ADDRESS"
          type="text"
          label="Physical Address*"
          placeholder="Street, Region & Country"
          onChange={(e) => {
            handleChangeSignUpFormData(e);
          }}
          value={signUpFormData.ADDRESS}
        />
        <FormInput
          name="BANKID"
          type="select"
          label="Bank*"
          placeholder="Choose bank"
          options={banks}
          onChange={(e) => {
            handleChangeSignUpFormData(e);
          }}
          hidden={true}
          value={1}
        />
        <FormInput
          name="USERCATEGORY"
          type="select"
          label="Who are you?*"
          options={userCategories}
          onChange={(e) => {
            handleChangeSignUpFormData(e);
          }}
          placeholder="Choose one"
          // hidden={isHiddenUserCategories}
          value={signUpFormData.USERCATEGORY}
        />

        <FormInput
          hidden={isHiddenAccountNo}
          name="ACCOUNTNUMBER"
          type="text"
          label="Account Number*"
          placeholder="acc/no in liquidated bank"
          onChange={(e) => {
            handleChangeSignUpFormData(e);
          }}
          value={signUpFormData.ACCOUNTNUMBER}
        />

        <FormButton
          text="SIGN UP"
          onClick={validateSignUp}
          isProcessing={isProcessing}
        />
      </div>
    );
  };

  return (
    <div className="al-body-wrap">
      <div className="al-container-60" id="left">
        <div className="al-black-text">
          <h1>Welcome to Deposit Insurance Board Portal</h1>
          <div className="al-normal-text al-mt-5">
            This portal provides a platform for information sharing among
            depositors, creditors as well as other stakeholders with interests
            and rights on the liquidation of banks in Tanzania. Membership to
            this platform is limited to members who will be given access by the
            portal administrator and share information accordingly. We welcome
            your comments, contributions and inquiries if any, on the on-going
            banks liquidation conducted by the Deposit Insurance Board (DIB).
          </div>
        </div>
      </div>
      <div className="al-container-40" id="right">
        <div className="al-tab-view">
          <div className="tabs">
            <div
              className={
                "tab " + (activeTab === "signin" ? "al-active-tab" : "")
              }
              onClick={() => {
                if (!isProcessing) setActiveTab("signin");
              }}
            >
              Sign in
            </div>
            <div
              className={
                "tab " + (activeTab === "signup" ? "al-active-tab" : "")
              }
              onClick={() => {
                if (!isProcessing) setActiveTab("signup");
              }}
            >
              Sign up
            </div>
          </div>
          <div className="tab-cotents">
            {activeTab === "signin"
              ? BuildSignInTabView()
              : BuildSignUpTabView()}
          </div>
        </div>
      </div>

      <ForgetPasswordDialog
        isOpen={isOpenForgetPwdDialog}
        onClose={() => {
          setIsOpenForgetPwdDialog(false);
        }}
      />

      <AlertDialog
        type={alertDialogType}
        variation={alertDialogVariation}
        message={alertDialogMessage}
        isOpen={isOpenAlertDialog}
        isFixed={true}
        onClose={() => {
          if (isSignUpSuccessful) {
            setActiveTab("signin");
            setIsSignUpSuccessful(false);
            window.location.reload();
          }
          setIsOpenAlertDialog(false);
        }}
        positiveButtonText={alertDialogPosBtnText}
        negativeButtonText={alertDialogNegBtnText}
      />
    </div>
  );
};

export default SignInSignUp;
