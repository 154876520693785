import React, { useState } from "react";
import Loader from "../Loader/Loader";
import "./OutlineButton.css";

const OutlineButton = ({
  text,
  isProcessing = false,
  disabled,
  onClick,
  color = "blue",
  width="",
}) => {
  const getButtonColorClass = () => {
    switch (color) {
      case "blue":
        return "btn-blue";
      case "red":
        return "btn-red";
      case "green":
        return "btn-green";
      default:
        return "btn-blue";
    }
  };

  return (

    <button
      className={"action-btn "+(getButtonColorClass())}
      disabled={isProcessing}
      onClick={onClick}
      style={{width:width}}
    >
      {!isProcessing ? text ?? "" : <Loader size={33} />}
    </button>
  );
};

export default OutlineButton;
