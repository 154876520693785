import React from "react";
import moment from "moment";
import "./EnquiryItem.css";

const EnquiryItem = ({title,date,onClick}) => {
  return (
    <div className="al-enquiry-item" onClick={onClick} >
      <div className="enq-title">{title}</div>
      <div className="enq-date">{moment(date).format("D-MMM-YYYY hh:mma")}</div>
    </div>
  );
};

export default EnquiryItem;
