export const validateFields = (fields) => {
  for (var i = 0; i < fields.length; i++) {
    if (fields[i].toString().trim() === "") {
      console.warn("field " + i + " is empty");
      return false;
    }
    // console.warn("field " + i + " is not empty");
  }
  return true;
};

export const validatePassword = (password) => {
  if (password.trim().length === "") {
    return false;
  }
  if (password.length < 6) {
    return false;
  }
  return true;
};

export const validatePhone = (phone) => {
  if (phone.length < 10) return false;
  return true;
};

export const validateEmail = (email) => {
  if (
    email.includes("@") &&
    email.includes(".") &&
    email.toString().trim().length >= 6
  ) {
    return true;
  } else return false;
};

export const validateAddress = (address) => {
  if (
    address.toString().trim().length < 5 ||
    !address.toString().trim().includes(" ")
  ) {
    return false;
  }
  return true;
};

export const validateSelect = (value) => {
  if (value.toString().trim() === "-1") {
    return false;
  }
  return true;
};
