import React from "react";
import loaderGif from "../../img/loader.gif";

import "./Loader.css";

const Loader = ({ size = "50" }) => {
  return (
    <div
      className="al-loader"
      style={{ height: size + "px", width: size + "px" }}
    >
      <img src={loaderGif} alt="Loading..." />
    </div>
  );
};

export default Loader;
