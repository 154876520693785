import { React, useState } from "react";
import "./FormInput.css";

const FormInput = ({
  type = "text",
  name,
  placeholder = "",
  label,
  options = [],
  onChange,
  noLabel = false,
  min = "",
  max = "",
  maxLength = "",
  minLength = "",
  disabled = false,
  hidden = false,
  value,
  onKeyUp,
}) => {
  const BuildInputItem = () => {
    if (type === "select") {
      return (
        <select name={name} onChange={onChange} disabled={disabled} >
          <option value="-1" >-- {placeholder} --</option>
          {options.map((option, index) => (
            <option value={option.value} selected={String(value) === String(option.value)} >
              {option.display}
            </option>
          ))}
        </select>
      );
    } else if (type === "textarea") {
      return (
        <textarea
          maxLength={maxLength}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        ></textarea>
      );
    } else {
      return (
        <input
          min={min}
          max={max}
          name={name}
          maxLength={maxLength}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
          onKeyUp={onKeyUp}
        />
      );
    }
  };

  if (!hidden)
    return (
      <div className="al-input-container">
        {!noLabel ? <div className="label">{label}</div> : <></>}
        {BuildInputItem()}
      </div>
    );
  else return <></>;
};

export default FormInput;
