import React, { useState } from "react";
import Loader from "../Loader/Loader"
import "./FormButton.css";

const FormButton = ({
  text,
  isProcessing = false,
  setIsProcessing,
  disabled,
  onClick,
}) => {
  return (
    <button className={!isProcessing?"al-form-button":"al-form-button-loading"} disabled={isProcessing} onClick={onClick} >
      {!isProcessing ? (text??"") : <Loader size={33}/>}
    </button>
  );
};

export default FormButton;
