import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SessionProvider from "react-session-provider";
import "./App.css";
import SignInSignUp from "./pages/SignInSignUp/SignInSignUp";
import TemplatePage from "./pages/TemplatePage/TemplatePage";
import Users from "./pages/Users/Users";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Publications from "./pages/Publications/Publications";
import Enquiries from "./pages/Enquiries/Enquiries";
import Profile from "./pages/Profile/Profile";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import UnauthorizedPage from "./pages/UnauthorizedPage/UnauthorizedPage";

function App() {
  return (
    <SessionProvider pKey="*dibportalsessionkey@" sessionName="app-session">
      <BrowserRouter>
        <Switch>
          <Route path="/" component={SignInSignUp} exact />
          {/* <Route path="/tmp" component={TemplatePage} /> */}
          <Route path="/users" component={Users} />
          <Route path="/publications" component={Publications} />
          <Route path="/enquiries" component={Enquiries} />
          <Route path="/profile" component={Profile} />
          <Route path="/changePassword/:tokenKey" component={ChangePassword} />
          <Route path="/error" component={UnauthorizedPage} />
          <Route component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    </SessionProvider>
  );
}

export default App;
