import { React, useState, useEffect } from "react";
import iconBell from "../../../img/icons/ic_bell_blue.png";
import Loader from "../../Loader/Loader";
import NotificationItem from "../../NotificationItem/NotificationItem";
import OutlineButton from "../../OutlineButton/OutlineButton";
import { useHistory } from "react-router-dom";
import { useSession } from "react-session-provider";
import {
  getUserNotificationsApi,
  updateNotificationApi,
} from "../../../static/ApiClient/NotificationApi";
import "./NotificationDialog.css";

const NotificationDialog = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState("unread");

  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [notifications, setNotifcations] = useState([]);
  const [isErrorNotfications, setIsErrorNotifications] = useState(false);

  const history = useHistory();
  const { store = {}, setKey } = useSession();
  const { sessionUser, sessionUserToken } = store;

  //useEffects
  useEffect(() => {
    if (isOpen && sessionUser) getNotifications();
  }, [sessionUser, isOpen]);

  //api calls
  const getNotifications = async () => {
    if (sessionUser) {
      setIsLoadingNotifications(true);

      const apiRes = await getUserNotificationsApi({
        token: sessionUserToken,
        data: {
          ID: sessionUser.ID,
          ROLEID: sessionUser.ROLEID,
        },
      });

      switch (apiRes.message) {
        case "success":
          setNotifcations(apiRes.data);
          break;
        case "error":
          setIsErrorNotifications(true);
          break;
        default:
          setIsErrorNotifications(true);
      }

      setIsLoadingNotifications(false);
    }
  };

  const dismissNotification = async (index) => {
    var notificationToDismiss = notifications.filter(
      (notification) => notification.READFLAG !== 1
    )[index];

    await updateNotificationApi({
      token: sessionUserToken,
      data: {
        NOTIFICATIONID: notificationToDismiss.NOTIFICATIONID,
        READFLAG: 1,
      },
    });

    getNotifications();
  };

  //view buiders

  const BuildNewNotificationViews = () => {
    if (!isLoadingNotifications) {
      if (!isErrorNotfications) {
        if (
          notifications.filter((notification) => notification.READFLAG !== 1)
            .length > 0
        ) {
          return (
            <div className="list">
              {notifications
                .filter((notification) => notification.READFLAG !== 1)
                .map((notification, index) => (
                  <NotificationItem
                    notification={notification}
                    theme={index % 2 === 0 ? "darker" : ""}
                    dismiss={() => {
                      dismissNotification(index);
                    }}
                  />
                ))}
            </div>
          );
        } else {
          return (
            <div className="list-empty al-muted-text">No notifications</div>
          );
        }
      } else {
        return (
          <div className="list-empty">
            <div className="flex-column">
              <div className="al-muted-text">Failed to load notifications</div>
              <OutlineButton
                color="blue"
                text="Reload"
                onClick={() => {
                  getNotifications();
                }}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="list-empty">
          <Loader size="60" />
        </div>
      );
    }
  };

  const BuildReadNotificationsView = () => {
    if (!isLoadingNotifications) {
      if (!isErrorNotfications) {
        if (
          notifications.filter((notification) => notification.READFLAG === 1)
            .length > 0
        ) {
          return (
            <div className="list">
              {notifications
                .filter((notification) => notification.READFLAG === 1)
                .map((notification, index) => (
                  <NotificationItem
                    notification={notification}
                    theme={index % 2 === 0 ? "darker" : ""}
                    isDismissable={false}
                  />
                ))}
            </div>
          );
        } else {
          return <div className="list-empty al-muted-text">Nothing here!</div>;
        }
      } else {
        return (
          <div className="list-empty">
            <div className="flex-column">
              <div className="al-muted-text">Failed to load notifications</div>
              <OutlineButton
                color="blue"
                text="Reload"
                onClick={() => {
                  getNotifications();
                }}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="list-empty">
          <Loader size="60" />
        </div>
      );
    }
  };

  if (isOpen)
    return (
      <div className="al-dialog-full">
        <div className="al-dialog-gesture-detector" onClick={onClose}></div>
        <div className="al-dialog-notification">
          <div className="header">
            <div className="top">
              <div className="left">
                <div className="icon">
                  <img src={iconBell} alt="i" />
                </div>
                <div className="text">All Notifications</div>
              </div>
              <div className="right">
                <button className="close-btn" onClick={onClose}>
                  X
                </button>
              </div>
            </div>
            <div className="bottom">
              <div className="tabs">
                <div
                  className={
                    "tab " + (activeTab === "unread" ? "active-tab" : "")
                  }
                  onClick={() => {
                    setActiveTab("unread");
                  }}
                >
                  Unread
                </div>
                <div
                  className={
                    "tab " + (activeTab === "read" ? "active-tab" : "")
                  }
                  onClick={() => {
                    setActiveTab("read");
                  }}
                >
                  Read
                </div>
              </div>
            </div>
          </div>
          {activeTab === "unread"
            ? BuildNewNotificationViews()
            : BuildReadNotificationsView()}
        </div>
      </div>
    );
  else return <></>;
};

export default NotificationDialog;
