import { React, useState } from "react";
import "./ForgetPasswordDialog.css";
import iconAvatar from "../../../img/icons/ic_avatar.png";
import FormInput from "../../Inputs/FormInput/FormInput";
import FormButton from "../../FormButton/FormButton";
import AlertDialog from "../AlertDialog/AlertDialog";
import {
  validateFields,
  validateEmail,
  validatePassword,
} from "../../../static/Validator/validator";
import {
  getUserByEmailApi,
  resetPasswordByCodeApi,
} from "../../../static/ApiClient/UsersApi";

const ForgetPasswordDialog = ({ isOpen = false, onClose }) => {
  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);

  const [alertDialogVariation, setAlertDialogVariation] = useState("confirm");
  const [alertDialogType, setAlertDialogType] = useState("warn");
  const [alertDialogMessage, setAlertDialogMessage] = useState("");
  const [alertDialogPosBtnText, setAlertDialogPosBtnText] = useState("");
  const [alertDialogNegBtnText, setAlertDialogNegBtnText] = useState("");
  const [activeView, setActiveView] = useState(1);

  const [isGettingUser, setIsGettingUser] = useState(false);
  const [isResetingPassowrd, setIsResetingPassword] = useState(false);
  const [user, setUser] = useState(null);

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");

  //api calls
  const getUser = async () => {
    setIsGettingUser(true);

    const apiRes = await getUserByEmailApi(email);

    switch (apiRes.message) {
      case "success":
        setUser(apiRes.data);
        setActiveView(2);
        break;
      case "no match":
        showAlertDialog({
          msg: "No user with such email exists in the portal",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
        showAlertDialog({
          msg: "An error occurred, try later",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    setIsGettingUser(false);
  };

  const resetPassword = async () => {
    setIsResetingPassword(true);
    let obj = {
      CODE: code,
      PASSWORD: password1,
      USERID: user.ID,
      EMAIL:email,
    };
    
    const apiRes = await resetPasswordByCodeApi(obj);

    switch (apiRes.message) {
      case "success":
        setActiveView(3);
        break;
      case "invalid code":
        showAlertDialog({
          msg: "Invalid password reset code",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      case "error":
        showAlertDialog({
          msg: "An error occurred, try again later",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
        showAlertDialog({
          msg: "An error occurred, try later",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    setIsResetingPassword(false);
  };

  //validators

  const valideSendEmail = () => {
    if (validateFields([email])) {
      if (validateEmail(email)) {
        getUser();
      } else
        showAlertDialog({
          msg: "Invalid email provided",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    } else
      showAlertDialog({
        msg: "Provide your email",
        type: "error",
        posText: "Ok",
        variation: "alert",
      });
  };

  const validatePasswordReset = () => {
    if (validateFields([code])) {
      if (validateFields([password1])) {
        if (validateFields([password2])) {
          if (password2 === password1) {
            resetPassword();
          } else
            showAlertDialog({
              msg: "Passwords do not match",
              type: "error",
              posText: "Ok",
              variation: "alert",
            });
        } else
          showAlertDialog({
            msg: "Confirm your new password",
            type: "error",
            posText: "Ok",
            variation: "alert",
          });
      } else
        showAlertDialog({
          msg: "Provide a new password",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    } else
      showAlertDialog({
        msg: "Provide the verification code",
        type: "error",
        posText: "Ok",
        variation: "alert",
      });
  };

  //show dialog
  const showAlertDialog = ({
    msg = "",
    type = "",
    posText = "",
    negText = "",
    variation = "",
  }) => {
    setAlertDialogMessage(msg);
    setAlertDialogNegBtnText(negText);
    setAlertDialogPosBtnText(posText);
    setAlertDialogType(type);
    setAlertDialogVariation(variation);
    setIsOpenAlertDialog(true);
  };

  //view builders
  const BuildDialogbBody = () => {
    switch (activeView) {
      case 1:
        return BuildEmailView();
      case 2:
        return BuildConfirmCodeView();
      case 3:
        return BuildSuccessView();
      default:
        return <></>;
    }
  };

  const BuildEmailView = () => {
    return (
      <div className="al-mt-20">
        <div className="al-container-100 padded">
          <FormInput
            label="Your Email"
            type="email"
            placeholder="email@example.com"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <FormButton
            text="Submit"
            isProcessing={isGettingUser}
            onClick={() => {
              valideSendEmail();
            }}
          />
        </div>
      </div>
    );
  };

  const BuildConfirmCodeView = () => {
    return (
      <div>
        <div className="al-desc">
          We have sent you and email with a verification code please enter your
          code and new password
        </div>
        <div className="al-container-100 padded">
          <FormInput
            label="Verification Code"
            type="text"
            placeholder="- - - -"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <FormInput
            label="Password"
            placeholder="Choose a password"
            type="password"
            value={password1}
            onChange={(e) => {
              setPassword1(e.target.value);
            }}
          />
          <FormInput
            label="Confirm Password"
            placeholder="Retype password"
            type="password"
            value={password2}
            onChange={(e) => {
              setPassword2(e.target.value);
            }}
          />
          <FormButton
            text="Submit"
            isProcessing={isResetingPassowrd}
            onClick={() => {
              validatePasswordReset();
            }}
          />
        </div>
      </div>
    );
  };

  const BuildSuccessView = () => {
    return (
      <>
        <div className="al-large-tick">
          <div className="al-icon">&#10004;</div>
        </div>
        <div className="al-desc al-centered">
          Your password has been reset successfully
        </div>
        <div className="al-container-100 padded">
          <FormButton text="Go to sign in" onClick={onClose} />
        </div>
      </>
    );
  };

  const BuildStepView = () => {
    return (
      <div className="al-step-view al-mt-20">
        <div className={"al-step " + (activeView >= 1 ? "al-active-step" : "")}>
          1
        </div>
        <div
          className={"al-step-line " + (activeView > 1 ? "al-active-line" : "")}
        ></div>
        <div className={"al-step " + (activeView >= 2 ? "al-active-step" : "")}>
          2
        </div>
        <div
          className={"al-step-line " + (activeView > 2 ? "al-active-line" : "")}
        ></div>
        <div className={"al-step " + (activeView >= 3 ? "al-active-step" : "")}>
          3
        </div>
      </div>
    );
  };

  if (isOpen)
    return (
      <>
        <div className="al-dialog-full">
          <div className="al-dialog-gesture-detector"></div>
          <div className="al-forget-password-dialog">
            <h3>Forgot Password</h3>
            {BuildStepView()}
            {BuildDialogbBody()}
            <button className="close-btn" onClick={onClose}>
              X
            </button>
          </div>
        </div>

        <AlertDialog
          type={alertDialogType}
          variation={alertDialogVariation}
          message={alertDialogMessage}
          isOpen={isOpenAlertDialog}
          positiveButtonMethod={() => {}}
          onClose={() => {
            setIsOpenAlertDialog(false);
          }}
          positiveButtonText={alertDialogPosBtnText}
          negativeButtonText={alertDialogNegBtnText}
        />
      </>
    );
  else return <></>;
};

export default ForgetPasswordDialog;
