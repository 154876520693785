import { React, useState, useEffect } from "react";
import "./ViewUserDialog.css";
import iconAvatar from "../../../img/icons/ic_avatar.png";
import FormInput from "../../Inputs/FormInput/FormInput";
import FormButton from "../../FormButton/FormButton";
import AlertDialog from "../AlertDialog/AlertDialog";
import OutlineButton from "../../OutlineButton/OutlineButton";
import { getBanksApi } from "../../../static/ApiClient/BanksApi";
import { useSession } from "react-session-provider";
import {
  approveOrRejectApi,
  getUserCategoriesApi,
  getUserRolesApi,
  updateUserApi,
  updateUserSignUpStatusApi,
} from "../../../static/ApiClient/UsersApi";

import {
  validateFields,
  validateEmail,
  validateAddress,
  validateSelect,
} from "../../../static/Validator/validator";

// import Tooltip from "react-simple-tooltip";
// import { css } from "styled-components";

const ViewUserDialog = ({
  isOpen = false,
  onClose,
  user,
  setUser = () => {},
  reloadParentList,
}) => {
  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);

  const [alertDialogVariation, setAlertDialogVariation] = useState("confirm");
  const [alertDialogType, setAlertDialogType] = useState("warn");
  const [alertDialogMessage, setAlertDialogMessage] = useState("");
  const [alertDialogPosBtnText, setAlertDialogPosBtnText] = useState("");
  const [alertDialogNegBtnText, setAlertDialogNegBtnText] = useState("");
  const [alertDialogWithTextField, setAlertDialogWithTextField] =
    useState(false);
  const [userCategories, setUserCategories] = useState([]);
  const [banks, setBanks] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [userRequestDeclineTextLabel, setDeclineTextLabel] = useState("");
  const [userRequestDeclineTextPh, setDeclineTextPh] = useState("");
  const [declineReason, setDeclineReason] = useState(null);

  const [userFormData, setUserFormData] = useState({});
  const [isUpdatingUserForm, setIsUpdatingUserForm] = useState(false);
  const [isUpdatingUserStatus, setIsUpdatingUserStatus] = useState(false);
  const [isUpdatingUserSignUpStatus, setIsUpdatingUserSignUpStatus] =
    useState(false);
  const [nextAction, setNextAction] = useState("");

  const { store = {}, setKey } = useSession();
  const { sessionUser, sessionUserToken } = store;

  //useEffects
  useEffect(() => {
    if (userFormData.BANKID && userFormData.BANKID !== -1) {
      getUserCategories();
    }
  }, [userFormData.BANKID]);

  useEffect(() => {
    getBanks();
    getRoles();
  }, []);

  useEffect(() => {
    if (user) {
      let tm = {
        FIRSTNAME: user.FIRSTNAME,
        MIDDLENAME: user.MIDDLENAME,
        LASTNAME: user.LASTNAME,
        EMAIL: user.EMAIL,
        ADDRESS: user.ADDRESS,
        ACCOUNTNUMBER: user.ACCOUNTNUMBER,
        BANKID: user.BANKID,
        USERCATEGORY: user.USERCATEGORY,
        ROLEID: user.ROLEID,
        PHONENUMBER: user.PHONENUMBER,
      };

      setUserFormData(tm);
      setDeclineReason(null);
    }
  }, [user]);

  //api calls
  const getUserCategories = async () => {
    const res = await getUserCategoriesApi(userFormData.BANKID);

    var temp = [];
    if (res.message === "success") {
      res.data.forEach((item, index) => {
        temp.push({ display: item.CATEGORYNAME, value: item.USERCATEGORYID });
      });

      setUserCategories(temp);
    }
  };

  const getBanks = async () => {
    const res = await getBanksApi();
    console.log(res);

    if (res.message === "success") {
      var temp = [];

      res.data.forEach((bank, index) => {
        temp.push({ display: bank.BANKNAME, value: bank.BANKID });
      });

      setBanks(temp);
    }
  };

  const getRoles = async () => {
    const res = await getUserRolesApi({ token: sessionUserToken });
    console.log(res);

    if (res.message === "success") {
      var temp = [];

      res.data.forEach((role, index) => {
        temp.push({ display: role.ROLENAME, value: role.ROLEID });
      });

      setUserRoles(temp);
    }
  };

  const updateUserActiveStatus = async (dis) => {
    setNextAction("");
    setIsUpdatingUserStatus(true);

    const res = await updateUserApi({
      EMAIL: user.EMAIL,
      DISABLED: dis,
      ID: user.ID,
    });

    switch (res.message) {
      case "success":
        user.DISABLED = dis;
        setUser(user);
        reloadParentList();
        showAlertDialog({
          msg: `User status updated successfully`,
          type: "success",
          posText: "Ok",
          variation: "alert",
        });
        break;
      case "error":
        showAlertDialog({
          msg: `Failed to change user status`,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;

      default:
        showAlertDialog({
          msg: res.message,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    setIsUpdatingUserStatus(false);
  };

  const updateUserSignUpStatus = async (sts) => {
    setNextAction("");
    setIsUpdatingUserSignUpStatus(true);

    const res = await updateUserSignUpStatusApi({
      token: sessionUserToken,
      data: {
        EMAIL: user.EMAIL,
        ID: user.ID,
        SIGNUPSTATUS: sts,
        FIRSTNAME: user.FIRSTNAME,
        LASTNAME: user.LASTNAME,
        SIGNUPREJECTREASON: declineReason,
      },
    });

    switch (res.message) {
      case "success":
        showAlertDialog({
          msg: `User request updated successfully`,
          type: "success",
          posText: "Ok",
          variation: "alert",
        });
        user.SIGNUPSTATUS = sts;
        setTimeout(() => {
          setUser(user);
        }, 2000);
        break;
      case "error":
        showAlertDialog({
          msg: `Failed to update request`,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
        showAlertDialog({
          msg: res.message,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    setIsUpdatingUserSignUpStatus(false);
  };

  const updateUserForm = async () => {
    setIsUpdatingUserForm(true);
    var tmp = userFormData;
    tmp.ID = user.ID;
    const res = await updateUserApi(tmp);

    switch (res.message) {
      case "success":
        setUser(userFormData);
        reloadParentList();
        showAlertDialog({
          msg: `User updated successfully`,
          type: "success",
          posText: "Ok",
          variation: "alert",
        });
        break;
      case "error":
        showAlertDialog({
          msg: `Failed to update user`,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
        showAlertDialog({
          msg: res.message,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    setIsUpdatingUserForm(false);
  };

  //other methods
  const handleChangeuserFormData = (e) => {
    e.persist();

    setUserFormData({
      ...userFormData,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  //show dialog
  const showAlertDialog = ({
    msg = "",
    type = "",
    posText = "",
    negText = "",
    variation = "",
  }) => {
    setAlertDialogMessage(msg);
    setAlertDialogNegBtnText(negText);
    setAlertDialogPosBtnText(posText);
    setAlertDialogType(type);
    setAlertDialogVariation(variation);
    setIsOpenAlertDialog(true);
  };

  //validator
  const validateUpdateUser = () => {
    const mandatoryFields = [
      userFormData.FIRSTNAME ?? "",
      userFormData.MIDDLENAME ?? "",
      userFormData.LASTNAME ?? "",
      userFormData.EMAIL ?? "",
      userFormData.ADDRESS ?? "",
      userFormData.BANKID ?? "",
      userFormData.USERCATEGORY ?? "",
      userFormData.ROLEID ?? "",
    ];

    if (validateFields(mandatoryFields)) {
      if (validateEmail(userFormData.EMAIL)) {
        if (validateAddress(userFormData.ADDRESS)) {
          if (validateSelect(userFormData.BANKID)) {
            if (validateSelect(userFormData.USERCATEGORY)) {
              if (
                (parseInt(userFormData.USERCATEGORY) === 2 &&
                  validateFields([userFormData.ACCOUNTNUMBER ?? ""])) ||
                parseInt(userFormData.USERCATEGORY) !== 2
              ) {
                updateUserForm();
              } else {
                showAlertDialog({
                  msg: "Please provide a valid account number",
                  type: "error",
                  posText: "Ok",
                  variation: "alert",
                });
              }
            } else
              showAlertDialog({
                msg: 'Pease choose one from "Who are you?", we need to know your role with the bank ',
                type: "error",
                posText: "Ok",
                variation: "alert",
              });
          } else
            showAlertDialog({
              msg: "Please select your bank",
              type: "error",
              posText: "Ok",
              variation: "alert",
            });
        } else
          showAlertDialog({
            msg: "Please provide a valid address including your country.Example, Kinondoni Dar es salaam Tanzania",
            type: "error",
            posText: "Ok",
            variation: "alert",
          });
      } else
        showAlertDialog({
          msg: "Invalid email provided",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    } else
      showAlertDialog({
        msg: "Provide all mandatory fields (With *)",
        type: "error",
        posText: "Ok",
        variation: "alert",
      });
  };

  //view builders

  const BuildUserStats = () => {
    return (
      <>
        <h3 className="al-mt-20">User Stats</h3>
        <div id="stats-container">
          <div className="stat-item">
            <div className="stat-left">Total Queries</div>
            <div className="stat-right">--</div>
          </div>
          <div className="stat-item">
            <div className="stat-left">Complaints</div>
            <div className="stat-right">--</div>
          </div>
          <div className="stat-item">
            <div className="stat-left">Comments</div>
            <div className="stat-right">--</div>
          </div>
          <div className="stat-item">
            <div className="stat-left">Suggestions</div>
            <div className="stat-right">--</div>
          </div>
        </div>
      </>
    );
  };

  const BuildActionButtons = () => {
    return (
      <>
        <h3 className="al-mt-60">Actions</h3>
        <div className="actions-container">
          {(() => {
            if (user.SIGNUPSTATUS === "approved") {
              if (parseInt(user.DISABLED) === 1) {
                return (
                  <OutlineButton
                    text="Activate User"
                    color="blue"
                    onClick={() => {
                      updateUserActiveStatus(0);
                    }}
                    isProcessing={isUpdatingUserStatus}
                  />
                );
              } else {
                return (
                  <OutlineButton
                    text={"Deactivate User"}
                    color="red"
                    isProcessing={isUpdatingUserStatus}
                    onClick={() => {
                      setNextAction("deactivateUser");
                      setAlertDialogMessage(
                        "Are you sure you want to deactivate this user?"
                      );
                      setAlertDialogNegBtnText("No");
                      setAlertDialogPosBtnText("Yes, I am");
                      setAlertDialogType("warn");
                      setAlertDialogVariation("confirm");
                      setIsOpenAlertDialog(true);
                    }}
                  />
                );
              }
            }
          })()}

          {(() => {
            if (user.SIGNUPSTATUS === "pending") {
              return (
                <>
                  <OutlineButton
                    text="Accept Request"
                    color="green"
                    isProcessing={isUpdatingUserSignUpStatus}
                    onClick={() => {
                      setNextAction("acceptUserRequest");
                      setAlertDialogMessage(
                        "Are you sure you want to accept this user's registration request?"
                      );
                      setAlertDialogNegBtnText("No");
                      setAlertDialogPosBtnText("Yes, I am");
                      setAlertDialogType("warn");
                      setAlertDialogVariation("confirm");
                      setIsOpenAlertDialog(true);
                    }}
                  />
                  <OutlineButton
                    text="Decline Request"
                    isProcessing={isUpdatingUserSignUpStatus}
                    color="red"
                    onClick={() => {
                      setNextAction("rejectUserRequest");
                      setAlertDialogMessage(
                        "Are you sure you want to decline this user's registration request?"
                      );
                      setAlertDialogNegBtnText("No");
                      setAlertDialogPosBtnText("Yes, I am");
                      setAlertDialogType("warn");
                      setAlertDialogVariation("confirm");
                      setAlertDialogWithTextField(true);
                      setDeclineTextLabel("Decline Reason");
                      setDeclineTextPh(
                        "Enter decline reason (max 500 characters)"
                      );
                      setIsOpenAlertDialog(true);
                    }}
                  />
                </>
              );
            }else if(user.SIGNUPSTATUS === "rejected"){
              return(
                <div className="al-muted-text al-mt-20" >User request rejected</div>
              )
            }
          })()}
        </div>
      </>
    );
  };

  const BuildUserDetailsForm = () => {
    return (
      <>
        <h3 className="al-mt-20">User Details</h3>
        <div className="avatar-wrapper">
          <div className="avatar">
            <img src={iconAvatar} alt="</>" />
          </div>
        </div>

        <div className="flex-container">
          <div className="al-container-50 padded">
            <FormInput
              type="text"
              label="First Name*"
              placeholder=""
              value={userFormData.FIRSTNAME}
              name="FIRSTNAME"
              onChange={(e) => {
                handleChangeuserFormData(e);
              }}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="text"
              label="Middle Name*"
              placeholder=""
              value={userFormData.MIDDLENAME}
              name="MIDDLENAME"
              onChange={(e) => {
                handleChangeuserFormData(e);
              }}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="text"
              label="Last Name*"
              placeholder=""
              value={userFormData.LASTNAME}
              name="LASTNAME"
              onChange={(e) => {
                handleChangeuserFormData(e);
              }}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="email"
              label="Email*"
              placeholder="email@example.com"
              value={userFormData.EMAIL}
              name="EMAIL"
              disabled={true}
              onChange={(e) => {
                handleChangeuserFormData(e);
              }}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="select"
              label="Bank*"
              placeholder="Choose bank"
              options={banks}
              value={userFormData.BANKID}
              name="BANKID"
              onChange={(e) => {
                handleChangeuserFormData(e);
              }}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="select"
              label="User Category*"
              options={userCategories}
              value={userFormData.USERCATEGORY}
              name="USERCATEGORY"
              placeholder="Choose one"
              onChange={(e) => {
                handleChangeuserFormData(e);
              }}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="select"
              label="User Role*"
              options={userRoles}
              value={userFormData.ROLEID}
              name="ROLEID"
              placeholder="Choose one"
              onChange={(e) => {
                handleChangeuserFormData(e);
              }}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="text"
              label="Account Number"
              placeholder="If depositor/creditor"
              value={userFormData.ACCOUNTNUMBER}
              name="ACCOUNTNUMBER"
              onChange={(e) => {
                handleChangeuserFormData(e);
              }}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="text"
              label="Physical Address*"
              placeholder="Street, City & Country"
              value={userFormData.ADDRESS}
              name="ADDRESS"
              onChange={(e) => {
                handleChangeuserFormData(e);
              }}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="text"
              label="Phone Number"
              placeholder="eg +255 000 000 000"
              value={userFormData.PHONENUMBER}
              name="PHONENUMBER"
              onChange={(e) => {
                handleChangeuserFormData(e);
              }}
            />
          </div>

          <div className="al-button-row">
            <div className="al-container-50 padded">
              <FormButton
                text="UPDATE"
                isProcessing={isUpdatingUserForm}
                onClick={validateUpdateUser}
              />
            </div>
          </div>

          <div className="white-space"></div>
        </div>
        <div className="white-space"></div>
      </>
    );
  };

  if (isOpen)
    return (
      <>
        <div className="al-dialog-full">
          <div className="al-dialog-gesture-detector" onClick={onClose}></div>
          <div className="al-user-view-dialog">
            <div className="left al-container-60">{BuildUserDetailsForm()}</div>
            <div className="right al-container-40">
              {BuildUserStats()}
              {BuildActionButtons()}
            </div>
            <button className="close-btn" onClick={onClose}>
              X
            </button>
          </div>
        </div>
        <AlertDialog
          type={alertDialogType}
          variation={alertDialogVariation}
          message={alertDialogMessage}
          isOpen={isOpenAlertDialog}
          positiveButtonMethod={() => {
            if (nextAction === "deactivateUser") {
              updateUserActiveStatus(1);
              setIsOpenAlertDialog(false);
            } else if (nextAction === "rejectUserRequest") {
              if (validateFields([declineReason ?? ""])) {
                updateUserSignUpStatus("rejected");
                setIsOpenAlertDialog(false);
                setAlertDialogWithTextField(false);
              } else alert("Please provide the reason for declining");
            } else if (nextAction === "acceptUserRequest") {
              updateUserSignUpStatus("approved");
              setIsOpenAlertDialog(false);
            }
          }}
          onClose={() => {
            setIsOpenAlertDialog(false);
          }}
          positiveButtonText={alertDialogPosBtnText}
          negativeButtonText={alertDialogNegBtnText}
          onChangeTextField={(e) => {
            setDeclineReason(e.target.value);
          }}
          textFieldLabel={userRequestDeclineTextLabel}
          textFieldPlaceholder={userRequestDeclineTextPh}
          textFieldValue={declineReason}
          withTextField={alertDialogWithTextField}
        />
      </>
    );
  else return <></>;
};

export default ViewUserDialog;
