import axios from "axios";
import { BACKEND_URL } from "../../Config";
const _apiKey = require("../apiKey.json")?.apiKey;

export const getAllEnquiriesApi = async ({ token }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey: _apiKey,
  };

  var res = await axios
    .get(BACKEND_URL + "enquiries", { headers: headers })
    .catch((e) => {
      console.log("***** get enquiries api error *****", e);
    })
    .catch((e) => {
      console.log("***** get enquiries api error *****", e);
    });
  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200 && res.data.message !== "unauthorized") {
      return res.data;
    } else if (
      parseInt(res.status) === 200 &&
      res.data.message === "unauthorized"
    ) {
      console.log("***** session expired ");
      return { message: "session expired", data: {} };
    } else {
      console.log(
        "***** get enquiries api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const postEnquiryApi = async ({ data, token }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey: _apiKey,
  };

  console.log("***** files are " + data.FILES);
  let formData = new FormData();
  var date = new Date();
  var dateTime =
    date.getFullYear() +
    "-" +
    date.getMonth() +
    "-" +
    date.getDay() +
    "_" +
    date.getHours() +
    "-" +
    date.getMinutes() +
    "-" +
    date.getSeconds();

  formData.append("ENQUIRYTITLE", data.ENQUIRYTITLE);
  formData.append("ENQUIRYCONTENT", data.ENQUIRYCONTENT);
  formData.append("DATETIME", dateTime);
  formData.append("USERID", data.USERID);
  formData.append("ACCESSIBILITY", data.ACCESSIBILITY);
  formData.append("BANKID", data.BANKID);
  formData.append("QUERYTYPEID", data.QUERYTYPEID);
  formData.append("HASATTACHMENT", data.FILES.length > 0 ? 1 : 0);

  data.FILES.forEach((item) => {
    console.log("*** appending", item);
    formData.append("file", item);
  });

  var res = await axios
    .post(BACKEND_URL + "enquiries", formData, { headers: headers })
    .catch((e) => {
      console.log("***** post enquiry api error *****", e);
    })
    .catch((e) => {
      console.log("***** post enquiry api error *****", e);
    });
  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) return res.data;
    else {
      console.log(
        "***** post enquiry api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const updateEnquiryApi = async ({ data, token }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey: _apiKey,
  };

  var res = await axios
    .patch(BACKEND_URL + "enquiries", data, { headers: headers })
    .catch((e) => {
      console.log("***** update enquiries api error *****", e);
    })
    .catch((e) => {
      console.log("***** update enquiries api error *****", e);
    });
  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) return res.data;
    else {
      console.log(
        "***** update enquiries api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const getEnquiryAttachmentsApi = async ({ token, enquiryId }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey: _apiKey,
  };

  var res = await axios
    .get(BACKEND_URL + `enquiries/attachments/${enquiryId}`, {
      headers: headers,
    })
    .catch((e) => {
      console.log("***** get enquiry attachments api error *****", e);
    })
    .catch((e) => {
      console.log("***** get enquiry attachments api error *****", e);
    });
  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) return res.data;
    else {
      console.log(
        "***** get enquiry attachments api error with status code ***** " +
          res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};
