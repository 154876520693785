import { React, useState, useEffect } from "react";

import Aside from "../../components/Aside/Aside";
import NotificationDialog from "../../components/dialogs/NotificationDialog/NotificationDialog";
import SideNav from "../../components/SideNav/SideNav";
import UserItem from "../../components/UserItem/UserItem";
import FormInput from "../../components/Inputs/FormInput/FormInput";
import FormButton from "../../components/FormButton/FormButton";
import iconAvatar from "../../img/icons/ic_avatar.png";
import iconAdd from "../../img/icons/ic_add_blue.png";
import { useHistory } from "react-router-dom";
import "./Users.css";
import Loader from "../../components/Loader/Loader";
import ViewUserDialog from "../../components/dialogs/ViewUserDialog/ViewUserDialog";
import AlertDialog from "../../components/dialogs/AlertDialog/AlertDialog";
import OutlineButton from "../../components/OutlineButton/OutlineButton";
import { useSession } from "react-session-provider";
import { getBanksApi } from "../../static/ApiClient/BanksApi";
import {
  addUserApi,
  getUserCategoriesApi,
  getUserRolesApi,
  getUsersApi,
  signOutApi,
} from "../../static/ApiClient/UsersApi";

import {
  validateFields,
  validateEmail,
  validateAddress,
  validateSelect,
  validatePassword,
} from "../../static/Validator/validator";

const Users = () => {
  const [isOPenNotification, setIsOpenNotification] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [isOpenUserViewDialog, setIsOpenUserViewDialog] = useState(false);
  const [userCategories, setUserCategories] = useState([]);
  const [banks, setBanks] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  const [newUserFormData, setNewUserFormData] = useState({
    FIRSTNAME: "",
    MIDDLENAME: "",
    LASTNAME: "",
    EMAIL: "",
    ADDRESS: "",
    ACCOUNTNUMBER: "",
    BANKID: "",
    USERCATEGORY: "",
    ROLEID: "",
    PHONENUMBER: "",
  });

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isGettingUsers, setIsGettingUsers] = useState(false);
  const [isErrorGetUsers, setIsErrorGetUsers] = useState(false);
  const [isAddingUser, setIsAddingUser] = useState(false);

  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);
  const [alertDialogVariation, setAlertDialogVariation] = useState("confirm");
  const [alertDialogType, setAlertDialogType] = useState("warn");
  const [alertDialogMessage, setAlertDialogMessage] = useState("");
  const [alertDialogPosBtnText, setAlertDialogPosBtnText] = useState("");
  const [alertDialogNegBtnText, setAlertDialogNegBtnText] = useState("");
  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState(false);

  const { store = {}, setKey, deleteKey } = useSession();
  const { sessionUser, sessionUserToken } = store;
  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredListUsers, setFilteredListUsers] = useState([]);

  //useEffects
  //page security
  useEffect(() => {
    if (sessionUser) {
      if (sessionUser.ROLEID === 1 || sessionUser.ROLEID === 2) {
        console.log("session ok");
      } else {
        history.push("/error");
      }
    } else {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (newUserFormData.BANKID && newUserFormData.BANKID !== -1) {
      getUserCategories();
    }
  }, [newUserFormData.BANKID]);

  useEffect(() => {
    getBanks();
    getUsers();
    getRoles();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      searchUser();
    }
  }, [searchQuery]);

  //api calls
  const getUserCategories = async () => {
    const res = await getUserCategoriesApi(newUserFormData.BANKID);

    var temp = [];
    if (res.message === "success") {
      res.data.forEach((item, index) => {
        temp.push({ display: item.CATEGORYNAME, value: item.USERCATEGORYID });
      });

      setUserCategories(temp);
    }
  };

  const getBanks = async () => {
    const res = await getBanksApi();
    console.log(res);

    if (res.message === "success") {
      var temp = [];

      res.data.forEach((bank, index) => {
        temp.push({ display: bank.BANKNAME, value: bank.BANKID });
      });

      setBanks(temp);
    }
  };

  const addUser = async () => {
    setIsAddingUser(true);
    var tmp = newUserFormData;
    tmp.SIGNUPSTATUS = "approved";
    const res = await addUserApi({ token: sessionUserToken, data: tmp });

    switch (res.message) {
      case "success":
        showAlertDialog({
          msg: "User added successfully",
          type: "success",
          posText: "Ok",
          variation: "alert",
        });

        setNewUserFormData({
          FIRSTNAME: "",
          MIDDLENAME: "",
          LASTNAME: "",
          EMAIL: "",
          ADDRESS: "",
          ACCOUNTNUMBER: "",
          BANKID: "",
          USERCATEGORY: "",
          ROLEID: "",
          PHONENUMBER: "",
        });

        getUsers();

        break;
      case "email exists":
        showAlertDialog({
          msg: `Error, a user with email address "${newUserFormData.EMAIL}" already exists in the portal`,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      case "error":
        showAlertDialog({
          msg: `An error occurred, try again later`,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
        showAlertDialog({
          msg: res.message,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    setIsAddingUser(false);
  };

  const getUsers = async () => {
    setIsErrorGetUsers(false);
    setIsGettingUsers(true);

    const res = await getUsersApi({ token: sessionUserToken });

    switch (res.message) {
      case "success":
        setUsers(res.data);
        break;
      case "session expired":
        setIsErrorGetUsers(true);
        const apiRes = await signOutApi(sessionUserToken);
        if (apiRes.message === "success") {
          console.log("signout successful");
        } else {
          console.log(apiRes.message);
        }
        //clear session
        deleteKey("sessionUser");
        deleteKey("sessionUserToken");
        history.push("/");
        break;
      case "error":
        setIsErrorGetUsers(true);
        break;
      default:
        setIsErrorGetUsers(true);
    }

    setIsGettingUsers(false);
  };

  const getRoles = async () => {
    const res = await getUserRolesApi({ token: sessionUserToken });
    console.log(res);

    if (res.message === "success") {
      var temp = [];

      res.data.forEach((role, index) => {
        temp.push({ display: role.ROLENAME, value: role.ROLEID });
      });

      setUserRoles(temp);
    }
  };

  //validators
  const validateAddNewUser = () => {
    const mandatoryFields = [
      newUserFormData.FIRSTNAME ?? "",
      newUserFormData.MIDDLENAME ?? "",
      newUserFormData.LASTNAME ?? "",
      newUserFormData.EMAIL ?? "",
      newUserFormData.ADDRESS ?? "",
      newUserFormData.BANKID ?? "",
      newUserFormData.USERCATEGORY ?? "",
      newUserFormData.ROLEID ?? "",
    ];

    if (validateFields(mandatoryFields)) {
      if (validateEmail(newUserFormData.EMAIL)) {
        if (validateAddress(newUserFormData.ADDRESS)) {
          if (validateSelect(newUserFormData.BANKID)) {
            if (validateSelect(newUserFormData.USERCATEGORY)) {
              if (
                (parseInt(newUserFormData.USERCATEGORY) === 2 &&
                  validateFields([newUserFormData.ACCOUNTNUMBER ?? ""])) ||
                parseInt(newUserFormData.USERCATEGORY) !== 2
              ) {
                addUser();
              } else {
                showAlertDialog({
                  msg: "Please provide a valid account number",
                  type: "error",
                  posText: "Ok",
                  variation: "alert",
                });
              }
            } else
              showAlertDialog({
                msg: 'Pease choose one from "Who are you?", we need to know your role with the bank ',
                type: "error",
                posText: "Ok",
                variation: "alert",
              });
          } else
            showAlertDialog({
              msg: "Please select your bank",
              type: "error",
              posText: "Ok",
              variation: "alert",
            });
        } else
          showAlertDialog({
            msg: "Please provide a valid address including your country.Example, Kinondoni Dar es salaam Tanzania",
            type: "error",
            posText: "Ok",
            variation: "alert",
          });
      } else
        showAlertDialog({
          msg: "Invalid email provided",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    } else
      showAlertDialog({
        msg: "Provide all mandatory fields (With *)",
        type: "error",
        posText: "Ok",
        variation: "alert",
      });
  };

  //other methods
  const handleChangeNewUserFormData = (e) => {
    e.persist();

    setNewUserFormData({
      ...newUserFormData,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const searchUser = () => {
    if (searchQuery.trim() !== "") {
      var temp = users.filter(
        (user) =>
          user.SIGNUPSTATUS === "approved" &&
          ((user.FIRSTNAME ?? "")
            .toLowerCase()
            .includes(searchQuery.trim().toLowerCase()) ||
            (user.MIDDLENAME ?? "")
              .toLowerCase()
              .includes(searchQuery.trim().toLowerCase()) ||
            (user.LASTNAME ?? "")
              .toLowerCase()
              .includes(searchQuery.trim().toLowerCase()) ||
            (user.PHONENUMBER ?? "")
              .toLowerCase()
              .includes(searchQuery.trim().toLowerCase()) ||
            (user.EMAIL ?? "")
              .toLowerCase()
              .includes(searchQuery.trim().toLowerCase()) ||
            (user.ROLENAME ?? "")
              .toLowerCase()
              .includes(searchQuery.trim().toLowerCase()) ||
            (user.ACCOUNTNUMBER ?? "")
              .toLowerCase()
              .includes(searchQuery.trim().toLowerCase()))
      );

      setFilteredListUsers(temp);
    } else {
      setFilteredListUsers([]);
    }
  };

  //show dialog
  const showAlertDialog = ({
    msg = "",
    type = "",
    posText = "",
    negText = "",
    variation = "",
  }) => {
    setAlertDialogMessage(msg);
    setAlertDialogNegBtnText(negText);
    setAlertDialogPosBtnText(posText);
    setAlertDialogType(type);
    setAlertDialogVariation(variation);
    setIsOpenAlertDialog(true);
  };

  //view builders

  const BuildTabViews = () => {
    switch (activeTab) {
      case "all":
        return BuildTabViewAllUsers();
      case "requests":
        return BuildTabViewRequests();
      case "add":
        return BuildTabViewAddUser();
      default:
        return <></>;
    }
  };

  const BuildTabViewAllUsers = () => {
    if (!isGettingUsers) {
      if (!isErrorGetUsers) {
        if (searchQuery.trim().length === 0) {
          if (
            users.filter((user) => user.SIGNUPSTATUS === "approved").length > 0
          ) {
            return (
              <div className="list">
                {users
                  .filter((user) => user.SIGNUPSTATUS === "approved")
                  .map((user, index) => (
                    <UserItem
                      fullname={user.FIRSTNAME + " " + user.LASTNAME}
                      role={user.ROLENAME ?? "N/A"}
                      status={user.DISABLED === 1 ? "inactive" : "active"}
                      onClick={() => {
                        setSelectedUser(user);
                        setIsOpenUserViewDialog(true);
                      }}
                    />
                  ))}
              </div>
            );
          } else {
            return (
              <div className="list-empty al-muted-text">No accepted users</div>
            );
          }
        } else {
          //search paramenters exist
          if (filteredListUsers.length > 0) {
            return (
              <div className="list">
                {filteredListUsers.map((user, index) => (
                  <UserItem
                    fullname={user.FIRSTNAME + " " + user.LASTNAME}
                    role={user.ROLENAME ?? "N/A"}
                    status={user.DISABLED === 1 ? "inactive" : "active"}
                    onClick={() => {
                      setSelectedUser(user);
                      setIsOpenUserViewDialog(true);
                    }}
                  />
                ))}
              </div>
            );
          } else {
            return (
              <div className="list-empty al-muted-text">No user found</div>
            );
          }
        }
      } else {
        return (
          <div className="list-empty">
            <div className="flex-column">
              <div className="al-muted-text">Failed to load Users</div>
              <OutlineButton
                color="blue"
                text="Reload"
                onClick={() => {
                  getUsers();
                }}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="list-empty">
          <Loader size="60" />
        </div>
      );
    }
  };

  const BuildTabViewRequests = () => {
    if (!isGettingUsers) {
      if (!isErrorGetUsers) {
        if (
          users.filter(
            (user) =>
              user.SIGNUPSTATUS !== "approved" &&
              user.SIGNUPSTATUS !== "rejected"
          ).length > 0
        ) {
          return (
            <div className="list">
              {users
                .filter(
                  (user) =>
                    user.SIGNUPSTATUS !== "approved" &&
                    user.SIGNUPSTATUS !== "rejected"
                )
                .map((user, index) => (
                  <UserItem
                    fullname={user.FIRSTNAME + " " + user.LASTNAME}
                    role={user.ROLENAME ?? "N/A"}
                    status={user.DISABLED === 1 ? "inactive" : "active"}
                    onClick={() => {
                      setSelectedUser(user);
                      setIsOpenUserViewDialog(true);
                    }}
                  />
                ))}
            </div>
          );
        } else {
          return (
            <div className="list-empty al-muted-text">No user requests</div>
          );
        }
      } else {
        return (
          <div className="list-empty">
            <div className="flex-column">
              <div className="al-muted-text">Failed to load Users</div>
              <OutlineButton
                color="blue"
                text="Reload"
                onClick={() => {
                  getUsers();
                }}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="list-empty">
          <Loader size="60" />
        </div>
      );
    }
  };

  const BuildTabViewAddUser = () => {
    return (
      <div className="list">
        <h3 className="al-mt-20"> Add User</h3>
        <div className="flex-container">
          <div className="al-container-50 padded">
            <FormInput
              type="text"
              label="First Name*"
              placeholder=""
              name="FIRSTNAME"
              onChange={(e) => {
                handleChangeNewUserFormData(e);
              }}
              value={newUserFormData.FIRSTNAME}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="text"
              label="Middle Name*"
              placeholder=""
              name="MIDDLENAME"
              onChange={(e) => {
                handleChangeNewUserFormData(e);
              }}
              value={newUserFormData.MIDDLENAME}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="text"
              label="Last Name*"
              placeholder=""
              name="LASTNAME"
              onChange={(e) => {
                handleChangeNewUserFormData(e);
              }}
              value={newUserFormData.LASTNAME}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="email"
              label="Email*"
              placeholder="email@example.com"
              name="EMAIL"
              onChange={(e) => {
                handleChangeNewUserFormData(e);
              }}
              value={newUserFormData.EMAIL}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="select"
              label="Bank*"
              placeholder="Choose bank"
              options={banks}
              name="BANKID"
              onChange={(e) => {
                handleChangeNewUserFormData(e);
              }}
              value={newUserFormData.BANKID}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="select"
              label="User Category*"
              options={userCategories}
              placeholder="Choose one"
              name="USERCATEGORY"
              onChange={(e) => {
                handleChangeNewUserFormData(e);
              }}
              value={newUserFormData.USERCATEGORY}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="select"
              label="User Role*"
              options={userRoles}
              placeholder="Choose one"
              name="ROLEID"
              onChange={(e) => {
                handleChangeNewUserFormData(e);
              }}
              value={newUserFormData.ROLEID}
            />
          </div>

          <div className="al-container-50 padded">
            <FormInput
              type="text"
              label="Account Number"
              options={userRoles}
              placeholder="Account number"
              name="ACCOUNTNUMBER"
              onChange={(e) => {
                handleChangeNewUserFormData(e);
              }}
              value={newUserFormData.ACCOUNTNUMBER}
            />
          </div>

          <div className="al-container-50 padded">
            <FormInput
              type="text"
              label="Physical Address*"
              placeholder="Street, City & Country"
              value={newUserFormData.ADDRESS}
              name="ADDRESS"
              onChange={(e) => {
                handleChangeNewUserFormData(e);
              }}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="text"
              label="Phone Number"
              placeholder="eg +255 000 000 000"
              value={newUserFormData.PHONENUMBER}
              name="PHONENUMBER"
              onChange={(e) => {
                handleChangeNewUserFormData(e);
              }}
            />
          </div>

          <div className="al-container-50 padded">
            <FormButton
              text="ADD"
              onClick={validateAddNewUser}
              isProcessing={isAddingUser}
            />
          </div>
          <div className="white-space"></div>
        </div>
      </div>
    );
  };

  const BuildSearchView = () => {
    if (activeTab === "all") {
      return (
        <div className="search-view">
          <div className="al-container-50">
            <FormInput
              type="text"
              placeholder="Find by name, email, account #, role name, phone"
              noLabel={true}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>
      );
    } else return <></>;
  };

  const BuildTabs = () => {
    return (
      <div className="tabs">
        <div
          className={"tab " + (activeTab === "all" ? "active-tab" : "")}
          onClick={() => {
            setActiveTab("all");
          }}
        >
          <div className="text">All Users</div>
          {(() => {
            if (
              users.filter((user) => user.SIGNUPSTATUS === "approved").length >
              0
            )
              return (
                <div className="count bg-green">
                  {
                    users.filter((user) => user.SIGNUPSTATUS === "approved")
                      .length
                  }
                </div>
              );
          })()}
        </div>
        <div
          className={"tab " + (activeTab === "requests" ? "active-tab" : "")}
          onClick={() => {
            setActiveTab("requests");
          }}
        >
          <div className="text">User Requests</div>
          {(() => {
            if (
              users.filter(
                (user) =>
                  user.SIGNUPSTATUS !== "approved" &&
                  user.SIGNUPSTATUS !== "rejected"
              ).length > 0
            )
              return (
                <div className="count bg-red">
                  {
                    users.filter(
                      (user) =>
                        user.SIGNUPSTATUS !== "approved" &&
                        user.SIGNUPSTATUS !== "rejected"
                    ).length
                  }
                </div>
              );
          })()}
        </div>
        <div
          className={"tab " + (activeTab === "add" ? "active-tab" : "")}
          onClick={() => {
            setActiveTab("add");
          }}
        >
          <div className="text">
            <div className="icon">
              <img src={iconAdd} alt="+" />
            </div>
            New User
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="al-body">
      <SideNav activePage="users" />
      <div className="al-main">
        <h1>Users</h1>
        <div className="al-users-tab-view al-mt-20">
          {BuildTabs()}
          {BuildSearchView()}
          {BuildTabViews()}
        </div>
      </div>
      <Aside
        expandNotifications={() => {
          setIsOpenNotification(true);
        }}
      />

      <NotificationDialog
        onClose={() => {
          setIsOpenNotification(false);
        }}
        isOpen={isOPenNotification}
      />

      <ViewUserDialog
        user={selectedUser}
        isOpen={isOpenUserViewDialog}
        onClose={() => {
          setIsOpenUserViewDialog(false);
        }}
        reloadParentList={() => {
          getUsers();
        }}
        setUsers={setUsers}
      />

      <AlertDialog
        type={alertDialogType}
        variation={alertDialogVariation}
        message={alertDialogMessage}
        isOpen={isOpenAlertDialog}
        isFixed={true}
        onClose={() => {
          if (isSignUpSuccessful) {
            setActiveTab("signin");
            setIsSignUpSuccessful(false);
            window.location.reload();
          }
          setIsOpenAlertDialog(false);
        }}
        positiveButtonText={alertDialogPosBtnText}
        negativeButtonText={alertDialogNegBtnText}
      />
    </div>
  );
};

export default Users;
