import React from "react";
import "./ActionIcon.css";

const ActionIcon = ({ toolTipMessage, icon, onClick }) => {
  return (
    <div className="action-icon" onClick={onClick} >
      <img src={icon} alt="i" />
    </div>
  );
};

export default ActionIcon;
