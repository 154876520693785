import axios from "axios";
import { BACKEND_URL } from "../../Config";
const _apiKey = require("../apiKey.json")?.apiKey;

export const getPublicationsApi = async ({ token }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey:_apiKey
  };

  var res = await axios
    .get(BACKEND_URL + `publications`, { headers: headers })
    .catch((e) => {
      console.log("***** publications api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200 && res.data.message !== "unauthorized") {
      console.log("***** publications returned *****", res.data);
      return res.data;
    } else if (
      parseInt(res.status) === 200 &&
      res.data.message === "unauthorized"
    ) {
      console.log("***** session expired ");
      return { message: "session expired", data: {} };
    } else {
      console.log(
        "***** publications api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const postPublicationApi = async ({ data, token }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey:_apiKey
  };

  console.log("***** files are " + data.files);
  let formData = new FormData();

  console.log("file is", data.files[0]);
  var date = new Date();
  var dateTime =
    date.getFullYear() +
    "-" +
    date.getMonth() +
    "-" +
    date.getDay() +
    "_" +
    date.getHours() +
    "-" +
    date.getMinutes() +
    "-" +
    date.getSeconds();

  formData.append("docTitle", data.docTitle);
  formData.append("flag", data.flag);
  formData.append("dateTime", dateTime);
  formData.append("userId", data.userId);
  formData.append("bankId", data.bankId);
  formData.append("file", data.files[0]);

  var res = await axios
    .post(BACKEND_URL + `publications`, formData, { headers: headers })
    .catch((e) => {
      console.log("***** post publication api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** post publication returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** post publication api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const updatePublicationApi = async ({ data, token }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
    apiKey:_apiKey
  };

  var res = await axios
    .patch(BACKEND_URL + `publications/${data.DOCUMENTID}`, data, {
      headers: headers,
    })
    .catch((e) => {
      console.log("***** update publication api error *****", e);
    });

  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) {
      console.log("***** update publication returned *****", res.data);
      return res.data;
    } else {
      console.log(
        "***** update publication api error with status code ***** " +
          res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};
