import { React } from "react";
import { useHistory } from "react-router-dom";
import { useSession } from "react-session-provider";

import "./UnauthorizedPage.css";

const UnauthorizedPage = () => {
  const history = useHistory();

  const { store = {}, deleteKey } = useSession();
  const { sessionUser, sessionUserToken } = store;

  return (
    <div className="al-body">
      <div className="al-error-page-wrapper">
        <div style={{ width: "100%" }}>
          <div className="heading">Oops!</div>
          <div className="message">Invalid request made!</div>
          <div className="button-row">
            <button
              onClick={() => {
                deleteKey("sessionUser");
                deleteKey("sessionUserToken");
                history.push("/");
              }}
            >
              Get me out of here!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
