import React from "react";
import Tooltip from "react-simple-tooltip";

import iconReply from "../../img/icons/ic_reply_black.png";
import iconDelete from "../../img/icons/ic_delete_black.png";
import iconAvatar from "../../img/icons/ic_avatar.png";
import iconPdf from "../../img/icons/ic_pdf_blue.png";
import iconForward from "../../img/icons/ic_send_to_black.png";
import iconVerified from "../../img/icons/ic_verified.png";
import moment from "moment";
import "./QueryItem.css";
import QueryAttachment from "../QueryAttachment/QueryAttachment";
import { useSession } from "react-session-provider";

const QueryItem = ({ query, assignUser, setQuotedQuery, deleteQuery }) => {
  const { store = {}, setKey } = useSession();
  const { sessionUser, sessionUserToken } = store;
  const BuildAvatar = () => {
    return (
      <div className="avatar">
        <img src={iconAvatar} alt="</>" />
      </div>
    );
  };

  const BuildQuery = () => {
    return (
      <div className="query">
        {BuildQuotedQuery()}
        <div className="content">{query.QUERYCONTENT}</div>
        <div className="date">
          {moment(query.DATEPOSTED).format("D-MMM-YYYY hh:mma")}
        </div>
        {BuildQueryAttachments()}
      </div>
    );
  };

  const BuildIcons = () => {
    return (
      <div className="icons">
        {(sessionUser.ROLEID === 1 || sessionUser.ROLEID === 2) && (
          <Tooltip
            content="Reply"
            background="dodgerblue"
            color="white"
            border="white"
            padding="10"
            fontSize="0.8rem"
            fadeDuration="100"
            placement="top"
          >
            <div
              className="icon-button"
              onClick={() => {
                setQuotedQuery(query);
              }}
            >
              <img src={iconReply} alt="reply" />
            </div>
          </Tooltip>
        )}
        {(query.QUERIEDBY === sessionUser.ID ||
          sessionUser.ROLEID === 1 ||
          sessionUser.ROLEID === 2) && (
          <Tooltip
            content="Delete"
            background="dodgerblue"
            color="white"
            border="white"
            padding="10"
            fontSize="0.8rem"
            fadeDuration="100"
            placement="top"
          >
            <div
              className="icon-button"
              onClick={() => {
                deleteQuery(query);
              }}
            >
              <img src={iconDelete} alt="delete" />
            </div>
          </Tooltip>
        )}

        {(sessionUser.ROLEID === 1 || sessionUser.ROLEID === 2) && (
          <Tooltip
            content="Assign"
            background="dodgerblue"
            color="white"
            border="white"
            padding="10"
            fontSize="0.8rem"
            fadeDuration="100"
            placement="top"
          >
            <div
              className="icon-button"
              onClick={() => {
                assignUser(query);
              }}
            >
              <img src={iconForward} alt="assign" />
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const BuildQueryAttachments = () => {
    if (query.ATTACHMENTS && query.ATTACHMENTS.length > 0) {
      return (
        <div className="attachments-flex">
          {query.ATTACHMENTS.map((attachment) => (
            <QueryAttachment
              title={attachment.DOCUMENTTITLE}
              filePath={attachment.DOCUMENTPATH}
            />
          ))}
        </div>
      );
    } else return <></>;
  };

  const BuildVerifiedIcon = () => {
    if (query.ROLEID === 1 || query.ROLEID === 2)
      return (
        <span className="al-verified">
          <img src={iconVerified} alt="&#10003;" />
        </span>
      );
    else return <></>;
  };

  const BuildQuotedQuery = () => {
    if (query.QUOTEDQUERY !== null) {
      return (
        <div className="quoted-message">
          <div className={"quoted-user "}>
            <span
              className={
                sessionUser.ID === query.QUOTEDQUERY.QUERIEDBY ? " al-isme" : ""
              }
            >
              {query.QUOTEDQUERY.FIRSTNAME +
                " " +
                (sessionUser.ID === query.QUOTEDQUERY.QUERIEDBY
                  ? "   (Me)  "
                  : "")}
            </span>
            said:
          </div>
          <div className="quoted-content">
            <span className="q">&ldquo;</span>{" "}
            <span className="message">{query.QUOTEDQUERY.QUERYCONTENT}</span>
          </div>
          <div className="quoted-date">
            {moment(query.QUOTEDQUERY.DATEPOSTED).format("D-MMM-YYYY hh:mma")}
          </div>
        </div>
      );
    }
  };

  const getTagColorClass = () => {
    switch (query.QUERYTYPE.toLowerCase()) {
      case "complaint":
        return "al-bg-red";
      case "suggestion":
        return "al-bg-gray";
      case "comment":
        return "al-bg-blue";
      case "question":
        return "al-bg-yellow";
      case "reply":
        return "al-bg-green";
      default:
        return "al-bg-blue";
    }
  };

  return (
    <div className={"al-query-item"}>
      <div className="left">{BuildAvatar()}</div>
      <div className="right">
        <div className="al-top">
          <div
            className={
              "al-name " + (sessionUser.ID === query.QUERIEDBY ? "al-isme" : "")
            }
          >
            {query.FIRSTNAME +
              " " +
              query.LASTNAME +
              (sessionUser.ID === query.QUERIEDBY ? "   (Me)" : "")}
            {BuildVerifiedIcon()}
          </div>
          <div className={"al-query-tag " + getTagColorClass()}>
            {query.QUERYTYPE}
          </div>
        </div>
        <div className="al-bottom">
          {BuildQuery()}
          {BuildIcons()}
        </div>
      </div>
    </div>
  );
};

export default QueryItem;
