import React from "react";
import moment from "moment";
import iconPdf from "../../img/icons/ic_pdf_blue.png";
import "./PublicationItem.css";

const PublicationItem = ({title,date,onClick}) => {
  return (
    <div className="publication-item" onClick={onClick} >
      <div className="left">
        <div className="icon">
          <img src={iconPdf} alt="PDF" />
        </div>
      </div>
      
      <div className="right">
        <div className="title">{title}</div>
        <div className="date">{moment(date).format("D-MMM-YYYY hh:mma")}</div>
      </div>
    </div>
  );
};

export default PublicationItem;
