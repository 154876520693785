import axios from "axios";
import { BACKEND_URL } from "../../Config";
const _apiKey = require("../apiKey.json")?.apiKey;

export const getQueryTypesApi = async () => {
  const headers = {
    Authorization: "Bearer my-token",
    apiKey: _apiKey,
  };

  var res = await axios
    .get(BACKEND_URL + "queries/query/types", { headers: headers })
    .catch((e) => {
      console.log("***** get query types api error *****", e);
    })
    .catch((e) => {
      console.log("***** get query types api error *****", e);
    });
  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) return res.data;
    else {
      console.log(
        "***** get query types api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const getDocumentQueriesApi = async ({ token, id }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
  };

  var res = await axios
    .get(BACKEND_URL + `queries/document/${id}`, { headers: headers })
    .catch((e) => {
      console.log("***** get document queries api error *****", e);
    })
    .catch((e) => {
      console.log("***** get document queries api error *****", e);
    });
  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) return res.data;
    else {
      console.log(
        "***** get document queries api error with status code ***** " +
          res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const getEnquiriesQueriesApi = async ({ token, id }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
  };

  var res = await axios
    .get(BACKEND_URL + `queries/enquiry/${id}`, { headers: headers })
    .catch((e) => {
      console.log("***** get enquiry queries api error *****", e);
    })
    .catch((e) => {
      console.log("***** get enquiry queries api error *****", e);
    });
  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) return res.data;
    else {
      console.log(
        "***** get enquiry queries api error with status code ***** " +
          res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const postQueryApi = async ({ token, data }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
  };

  console.log("***** files are " + data.FILES);
  let formData = new FormData();
  var date = new Date();
  var dateTime =
    date.getFullYear() +
    "-" +
    date.getMonth() +
    "-" +
    date.getDay() +
    "_" +
    date.getHours() +
    "-" +
    date.getMinutes() +
    "-" +
    date.getSeconds();

  formData.append("DOCUMENTID", data.DOCUMENTID);
  formData.append("ENQUIRYID", data.ENQUIRYID);
  formData.append("QUOTEDQUERYID", data.QUOTEDQUERYID);
  formData.append("QUERYCONTENT", data.QUERYCONTENT);
  formData.append("DATETIME", dateTime);
  formData.append("USERID", data.USERID);
  formData.append("QUERYTYPEID", data.QUERYTYPEID);
  formData.append("HASATTACHMENT", data.FILES.length > 0 ? 1 : 0);

  data.FILES.forEach((item) => {
    console.log("*** appending", item);
    formData.append("file", item);
  });

  console.log("form data  is", formData.get("file"));

  var res = await axios
    .post(BACKEND_URL + "queries", formData, { headers: headers })
    .catch((e) => {
      console.log("***** post query api error *****", e);
    })
    .catch((e) => {
      console.log("***** post query api error *****", e);
    });
  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) return res.data;
    else {
      console.log(
        "***** post query api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};

export const updateQueryApi = async ({ token, data }) => {
  const headers = {
    Authorization: "Bearer my-token",
    userToken: token,
  };

  let id = data.QUERYID;

  var res = await axios
    .patch(BACKEND_URL + `queries/${id}`, data, { headers: headers })
    .catch((e) => {
      console.log("***** update query api error *****", e);
    })
    .catch((e) => {
      console.log("***** update query api error *****", e);
    });
  if (typeof res !== "undefined") {
    if (parseInt(res.status) === 200) return res.data;
    else {
      console.log(
        "***** update query api error with status code ***** " + res.status
      );
      return { message: "error " + res.status, data: {} };
    }
  } else return { message: "server error", data: {} };
};
