import React from "react";
import "./QueryAttachment.css";

import iconPdf from "../../img/icons/ic_pdf_blue.png";
import { BACKEND_URL } from "../../Config";

const QueryAttachment = ({ title, filePath, onRemove, removable = false }) => {
  const openDocument = () => {
    window.open(`${BACKEND_URL}${filePath}`);
  };

  return (
    <div className="al-query-attachment">
      <div className="icon" onClick={openDocument}>
        <img src={iconPdf} alt="PDF" />
      </div>
      <div className="at-title" onClick={openDocument}>
        {title}
      </div>
      {(() => {
        if (removable) {
          return (
            <div className="ic_remove" onClick={onRemove}>
              X
            </div>
          );
        } else return <></>;
      })()}
    </div>
  );
};

export default QueryAttachment;
