import React from "react";
import "./UserItem.css";
import iconAvatar from "../../img/icons/ic_avatar.png"

const UserItem = ({avatar,fullname,role,status="",onClick}) => {
  return (
    <div className="al-user-item" onClick={onClick} >
      <div className="left">
        <div className="avatar">
          <img src={avatar??iconAvatar} alt="</>" />
        </div>
        <div className="text">{fullname}</div>
      </div>
      <div className="right">
         <div className={"tag "+(status === "active"?"bg-green":"bg-red")} >{status.toLowerCase()}</div> 
        <div className="text">{role}</div>
      </div>
    </div>
  );
};

export default UserItem;
