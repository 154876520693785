import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { getEnquiryAttachmentsApi } from "../../../static/ApiClient/EnquiriesApi";
import {
  getEnquiriesQueriesApi,
  postQueryApi,
  updateQueryApi,
} from "../../../static/ApiClient/QueriesApi";
import QueriesContainer from "../../QueriesContainer/QueriesContainer";
import QueryAttachment from "../../QueryAttachment/QueryAttachment";
import UserSelectDialog from "../UserSelectDialog/UserSelectDialog";
import AlertDialog from "../AlertDialog/AlertDialog";
import { useSession } from "react-session-provider";

import "./ForumDialog.css";

const ForumDialog = ({ isOpen, onClose, enquiry }) => {
  const [attachments, setAttachments] = useState([]);
  const [isOPenUserSelectDialog, setIsOpenUserSelectDialog] = useState(false);

  const [queries, setQueries] = useState([]);
  const [isLoadingQueries, setIsLoadingQueries] = useState(false);
  const [isErrorQueries, setIsErrorQueries] = useState(false);

  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);
  const [alertDialogVariation, setAlertDialogVariation] = useState("confirm");
  const [alertDialogType, setAlertDialogType] = useState("warn");
  const [alertDialogMessage, setAlertDialogMessage] = useState("");
  const [alertDialogPosBtnText, setAlertDialogPosBtnText] = useState("");
  const [alertDialogNegBtnText, setAlertDialogNegBtnText] = useState("");

  const [queryContent, setQueryContent] = useState("");
  const [queryTypeId, setQueryTypeId] = useState(1);
  const [quotedQuery, setQuotedQuery] = useState(null);
  const [isSendingQuery, setIsSendingQuery] = useState(false);
  const [isRefreshingAttach, setIsRefreshingAttach] = useState(false);

  const [nextAction, setNextAction] = useState("");
  const [queryOnHold, setQueryOnHold] = useState(null);

  const [assignedToUser, setAssignedToUser] = useState(null);
  const [assignedQuery, setAssignedQuery] = useState(null);

  /* Files */
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  /* Files */

  const { store = {}, setKey } = useSession();
  const { sessionUser, sessionUserToken } = store;

  //useEffect
  useEffect(() => {
    if (enquiry) {
      getAttachments();
      getEnquiryQueries();
    }
  }, [enquiry]);

  useEffect(() => {
    if (assignedToUser) assignQueryToUser();
  }, [assignedToUser]);

  //api calls
  const getAttachments = async () => {
    setAttachments([]);
    const res = await getEnquiryAttachmentsApi({
      token: sessionUserToken,
      enquiryId: enquiry.ENQUIRYID,
    });
    switch (res.message) {
      case "success":
        setAttachments(res.data);
        break;
      default:
        setAttachments([]);
    }
  };

  const getEnquiryQueries = async () => {
    setIsLoadingQueries(true);
    setQueries([]);
    const res = await getEnquiriesQueriesApi({
      token: sessionUserToken,
      id: enquiry.ENQUIRYID,
    });
    console.log("queries returned ", res);
    switch (res.message) {
      case "success":
        setIsErrorQueries(false);
        setQueries(res.data);
        break;
      case "error":
        setIsErrorQueries(true);
        setQueries([]);
        break;
      default:
        setQueries([]);
    }

    setIsLoadingQueries(false);
  };

  const postQuery = async () => {
    setIsSendingQuery(true);
    var data = {
      ENQUIRYID: enquiry ? enquiry.ENQUIRYID : null,
      DOCUMENTID: null,
      USERID: sessionUser.ID,
      QUERYCONTENT: queryContent,
      QUERYTYPEID: queryTypeId,
      QUOTEDQUERYID: quotedQuery ? quotedQuery.QUERYID : null,
      FILES: attachmentFiles,
    };

    const apiRes = await postQueryApi({ token: sessionUserToken, data: data });

    switch (apiRes.message) {
      case "success":
        setQueryContent("");
        setQuotedQuery(null);
        setQueryTypeId(1);
        setIsRefreshingAttach(true);
        setAttachmentFiles([]);
        getEnquiryQueries();
        setTimeout(() => {
          setIsRefreshingAttach(false);
        }, 2000);

        break;
      default:
        console.log("***** error occurred", apiRes);
        showAlertDialog({
          msg: "An error occurred, failed to post",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }
    setIsSendingQuery(false);
  };

  const deleteQuery = async (query) => {
    var obj = {
      QUERYID: query.QUERYID,
      FLAG: "deleted",
    };

    const apiRes = await updateQueryApi({ token: sessionUserToken, data: obj });

    switch (apiRes.message) {
      case "success":
        setQueryOnHold(null);
        showAlertDialog({
          msg: "Query successfully deleted",
          type: "success",
          posText: "Ok",
          variation: "alert",
        });
        getEnquiryQueries();
        break;
      case "error":
        showAlertDialog({
          msg: "An error occurred, failed to make changes",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
    }
  };

  const assignQueryToUser = async () => {
    alert(
      `assigning query ${JSON.stringify(queryOnHold)} to user ${JSON.stringify(
        assignedToUser
      )}`
    );
  };

  //other methods
  const showAlertDialog = ({
    msg = "",
    type = "",
    posText = "",
    negText = "",
    variation = "",
    isFixed = false,
  }) => {
    setAlertDialogMessage(msg);
    setAlertDialogNegBtnText(negText);
    setAlertDialogPosBtnText(posText);
    setAlertDialogType(type);
    setAlertDialogVariation(variation);
    setIsOpenAlertDialog(true);
  };

  //other methods
  const handleClickAssignUser = (query) => {
    setIsOpenUserSelectDialog(true);
  };

  const confirmDeleteQuery = (query) => {
    setNextAction("deleteQuery");
    setQueryOnHold(query);
    showAlertDialog({
      msg: "Are you sure you want to delete this entry?",
      type: "warn",
      posText: "Yes, Delete",
      negText: "No",
      variation: "confirm",
    });
  };

  //view builders
  const BuildHeader = () => {
    return (
      <div className="al-forum-header">
        <div className="al-forum-title">{enquiry.ENQUIRYTITLE}</div>
        <div className="al-forum-user">
          {enquiry.FIRSTNAME + " " + enquiry.LASTNAME}
        </div>
        <div className="al-forum-content">{enquiry.ENQUIRYCONTENT}</div>
        <div className="al-forum-attachments">
          {attachments.map((attachment) => (
            <QueryAttachment
              title={attachment.DOCUMENTTITLE ?? "It is empty"}
              filePath={attachment.DOCUMENTPATH}
            />
          ))}
        </div>
        <div className="al-forum-tag-wrap">
          <div className={"al-query-tag-forum " + getTagColorClass()}>
            {enquiry.QUERYTYPE}
          </div>
        </div>
        <div className="al-forum-date">
          {moment(enquiry.DATEPOSTED).format("D-MMM-YYYY hh:mma")}
        </div>
      </div>
    );
  };

  const BuildCommentsList = () => {
    return (
      <div className="al-comments-list">
        <QueriesContainer
          queries={queries}
          refreshList={getEnquiryQueries}
          isLoadingQueries={isLoadingQueries}
          isErrorQueries={isErrorQueries}
          postQuery={postQuery}
          showAlertDialog={showAlertDialog}
          attachmentFiles={attachmentFiles}
          setAttachmentFiles={setAttachmentFiles}
          setQueryContent={setQueryContent}
          queryContent={queryContent}
          setQuotedQuery={setQuotedQuery}
          quotedQuery={quotedQuery}
          isSendingQuery={isSendingQuery}
          isRefreshingAttach={isRefreshingAttach}
          setIsRefreshingAttach={setIsRefreshingAttach}
          deleteQuery={confirmDeleteQuery}
          assignUser={handleClickAssignUser}
        />
      </div>
    );
  };

  const getTagColorClass = () => {
    switch (enquiry.QUERYTYPE) {
      case "complaint":
        return "al-bg-red";
      case "suggestion":
        return "al-bg-gray";
      case "comment":
        return "al-bg-blue";
      case "question":
        return "al-bg-yellow";
      case "reply":
        return "al-bg-green";
      default:
        return "al-bg-blue";
    }
  };

  if (isOpen)
    return (
      <div className="al-dialog-full">
        <div className="al-dialog-gesture-detector" onClick={onClose}></div>
        <div className="al-forum-dialog">
          {BuildHeader()}
          {BuildCommentsList()}
          <button className="close-btn" onClick={onClose}>
            X
          </button>
        </div>

        <UserSelectDialog
          selectUser={setAssignedToUser}
          isOPen={isOPenUserSelectDialog}
          onClose={() => {
            setIsOpenUserSelectDialog(false);
          }}
        />

        <AlertDialog
          type={alertDialogType}
          variation={alertDialogVariation}
          message={alertDialogMessage}
          isOpen={isOpenAlertDialog}
          isFixed={true}
          positiveButtonMethod={() => {
            if (nextAction === "deleteQuery") deleteQuery(queryOnHold);
            setIsOpenAlertDialog(false);
          }}
          onClose={() => {
            setIsOpenAlertDialog(false);
          }}
          positiveButtonText={alertDialogPosBtnText}
          negativeButtonText={alertDialogNegBtnText}
        />
      </div>
    );
  else return <></>;
};

export default ForumDialog;
