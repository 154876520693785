import { React, useState, useEffect, useRef } from "react";

import Aside from "../../components/Aside/Aside";
import NotificationDialog from "../../components/dialogs/NotificationDialog/NotificationDialog";
import SideNav from "../../components/SideNav/SideNav";
import FormInput from "../../components/Inputs/FormInput/FormInput";
import FormButton from "../../components/FormButton/FormButton";
import Tooltip from "react-simple-tooltip";
import AlertDialog from "../../components/dialogs/AlertDialog/AlertDialog";

import iconAttachmentBlue from "../../img/icons/ic_attachment_black.png";

import iconAdd from "../../img/icons/ic_add_blue.png";

import "./Publications.css";
import PublicationItem from "../../components/PublicationItem/PublicationItem";
import ViewPublicationDialog from "../../components/dialogs/ViewPublicationDialog/ViewPublicationDialog";
import Loader from "../../components/Loader/Loader";
import ActionIcon from "../../components/ActionIcon/ActionIcon";
import {
  getPublicationsApi,
  postPublicationApi,
} from "../../static/ApiClient/PublicationsApi";
import OutlineButton from "../../components/OutlineButton/OutlineButton";
import QueryAttachment from "../../components/QueryAttachment/QueryAttachment";
import { validateFields } from "../../static/Validator/validator";
import { useSession } from "react-session-provider";
import { useHistory } from "react-router-dom";
import { signOutApi } from "../../static/ApiClient/UsersApi";

const Publications = () => {
  const [isOPenNotification, setIsOpenNotification] = useState(false);
  const [isOpenViewPubDialog, setIsOpenViewPubDialog] = useState(false);
  const [activeTab, setActiveTab] = useState("all");

  const [publications, setPublications] = useState([]);
  const [isErrorPublications, setIsErrorPublications] = useState(false);
  const [isGettingPublications, setIsGettingPublications] = useState(false);
  const [isPostingPubcalition, setIsPostingPublication] = useState(false);
  const [isSavingDraftPublication, setIsSavingDraftPublication] =
    useState(false);

  const [selectedPublication, setSelectedPublication] = useState(null);

  const [newPublicationTitle, setNewPublicationTitle] = useState("");

  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);
  const [alertDialogVariation, setAlertDialogVariation] = useState("confirm");
  const [alertDialogType, setAlertDialogType] = useState("warn");
  const [alertDialogMessage, setAlertDialogMessage] = useState("");
  const [alertDialogPosBtnText, setAlertDialogPosBtnText] = useState("");
  const [alertDialogNegBtnText, setAlertDialogNegBtnText] = useState("");

  /* Files */
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const hiddenFileInput = useRef(null);
  const [isRefreshingAttach, setIsRefreshingAttach] = useState(false);
  /* Files */

  const { store = {}, setKey, deleteKey } = useSession();
  const { sessionUser, sessionUserToken } = store;
  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredListPublications, setFilteredListPublications] = useState([]);

  //useStates
  //page security
  useEffect(() => {
    if (sessionUser) {
      // if (sessionUser.ROLEID === 1 || sessionUser.ROLEID === 2) {
      //   console.log("session ok");
      // } else {
      //   history.push("/error");
      // }
    } else {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    getPublications();
  }, []);

  useEffect(() => {
    if (publications.length > 0) {
      searchPublication();
    }
  }, [searchQuery]);

  //api calls
  const getPublications = async () => {
    setIsGettingPublications(true);
    const res = await getPublicationsApi({ token: sessionUserToken });
    switch (res.message) {
      case "success":
        setIsErrorPublications(false);
        setPublications(res.data);
        break;
      case "error":
        setIsErrorPublications(true);
        break;
      case "session expired":
        setIsErrorPublications(true);
        const apiRes = await signOutApi(sessionUserToken);
        if (apiRes.message === "success") {
          console.log("signout successful");
        } else {
          console.log(apiRes.message);
        }
        //clear session
        deleteKey("sessionUser");
        deleteKey("sessionUserToken");
        history.push("/");
        break;
      default:
        setIsErrorPublications(true);
        break;
    }
    setIsGettingPublications(false);
  };

  const postPublication = async ({ isDraft }) => {
    if (isDraft) setIsSavingDraftPublication(true);
    else setIsPostingPublication(true);
    console.log(attachmentFiles);
    const res = await postPublicationApi({
      token: sessionUserToken,
      data: {
        docTitle: newPublicationTitle,
        flag: isDraft ? "draft" : "published",
        files: attachmentFiles,
        bankId: sessionUser.BANKID,
        userId: sessionUser.ID,
      },
    });

    switch (res.message) {
      case "success":
        showAlertDialog({
          msg: "Publication posted successfully",
          type: "success",
          posText: "Ok",
          variation: "alert",
        });
        getPublications();
        setNewPublicationTitle("");
        setIsRefreshingAttach(true);
        setAttachmentFiles([]);
        setTimeout(() => {
          setIsRefreshingAttach(false);
        }, 2000);
        break;
      case "error":
        showAlertDialog({
          msg: "An error occurred, try again later",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
        showAlertDialog({
          msg: res.message,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    if (isDraft) setIsSavingDraftPublication(false);
    else setIsPostingPublication(false);
  };

  //other methods
  const verifyPostPublication = ({ isDraft = false }) => {
    if (validateFields([newPublicationTitle])) {
      if (attachmentFiles.length > 0) {
        postPublication({ isDraft: isDraft });
      } else
        showAlertDialog({
          msg: "Provide a PDF file attachment for the publication",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    } else {
      showAlertDialog({
        msg: "Provide a publication title",
        type: "error",
        posText: "Ok",
        variation: "alert",
      });
    }
  };

  const searchPublication = () => {
    if (searchQuery.trim() !== "") {
      var temp = publications
        .filter((doc) => doc.FLAG === "published")
        .filter((doc) =>
          (doc.DOCTITLE ?? "")
            .toLowerCase()
            .includes(searchQuery.trim().toLowerCase())
        );
      setFilteredListPublications(temp);
    } else {
      setFilteredListPublications([]);
    }
  };

  /* Files */
  const handleFileChange = (e) => {
    setIsRefreshingAttach(true);

    if (typeof e.target.files[0] !== "undefined") {
      //check duplicate
      let isDuplicate = false;
      attachmentFiles.forEach((file, index) => {
        if (file.name === e.target.files[0].name) {
          showAlertDialog({
            msg: "File has already been chosen",
            type: "error",
            posText: "Ok",
            variation: "alert",
          });
          isDuplicate = true;
        }
      });

      if (!isDuplicate) {
        const fileUploaded = e.target.files[0];
        const extension = fileUploaded.name.split(".").pop();

        if (extension === "pdf") {
          var tmp = attachmentFiles;
          tmp.push(fileUploaded);
          setTimeout(() => {
            setAttachmentFiles(tmp);
          }, 500);
        } else
          showAlertDialog({
            msg: "Invalid file type, only PDF format is allowed",
            type: "error",
            posText: "Got it",
            variation: "alert",
          });
      }
    }
    setTimeout(() => {
      setIsRefreshingAttach(false);
    }, 3000);
  };

  const showAlertDialog = ({
    msg = "",
    type = "",
    posText = "",
    negText = "",
    variation = "",
    isFixed = false,
  }) => {
    setAlertDialogMessage(msg);
    setAlertDialogNegBtnText(negText);
    setAlertDialogPosBtnText(posText);
    setAlertDialogType(type);
    setAlertDialogVariation(variation);
    setIsOpenAlertDialog(true);
  };

  const chooseFile = () => {
    hiddenFileInput.current.value = "";
    if (attachmentFiles.length < 1) {
      //maximum files
      hiddenFileInput.current.click();
    } else
      showAlertDialog({
        msg: "Maximum upload is 1 file(s)",
        type: "error",
        posText: "Got it",
        variation: "alert",
      });
  };

  const removeFile = (index) => {
    setIsRefreshingAttach(true);
    var tmp = attachmentFiles;
    tmp.splice(index, 1);
    setAttachmentFiles(tmp);
    setTimeout(() => {
      setIsRefreshingAttach(false);
    }, 3000);
  };
  /* Files */

  //view builders
  const BuildListPublications = () => {
    if (!isGettingPublications) {
      if (!isErrorPublications) {
        if (searchQuery.trim().length === 0) {
          if (
            publications.filter((doc) => doc.FLAG === "published").length > 0
          ) {
            return (
              <div className="list">
                {publications
                  .filter((doc) => doc.FLAG === "published")
                  .map((doc) => (
                    <PublicationItem
                      title={doc.DOCTITLE}
                      date={doc.UPLOADDATE} //29-Jul-2021 08:00am
                      onClick={() => {
                        setSelectedPublication(doc);
                        setIsOpenViewPubDialog(true);
                      }}
                    />
                  ))}
              </div>
            );
          } else {
            return (
              <div className="list-empty al-muted-text">No publications</div>
            );
          }
        } else {
          //search paramenters exist
          if (filteredListPublications.length > 0) {
            return (
              <div className="list">
                {filteredListPublications.map((doc, index) => (
                  <PublicationItem
                    title={doc.DOCTITLE}
                    date={doc.UPLOADDATE} //29-Jul-2021 08:00am
                    onClick={() => {
                      setSelectedPublication(doc);
                      setIsOpenViewPubDialog(true);
                    }}
                  />
                ))}
              </div>
            );
          } else {
            return (
              <div className="list-empty al-muted-text">No user found</div>
            );
          }
        }
      } else {
        return (
          <div className="list-empty">
            <div className="flex-column">
              <div className="al-muted-text">Failed to load pubcalitions</div>
              <OutlineButton
                color="blue"
                text="Reload"
                onClick={getPublications}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="list-empty">
          <Loader size={60} />
        </div>
      );
    }
  };

  const BuildTabs = () => {
    return (
      <div className="tabs">
        <div
          className={"tab " + (activeTab === "all" ? "active-tab" : "")}
          onClick={() => {
            setActiveTab("all");
          }}
        >
          <div className="text">All Publications</div>
        </div>
        {sessionUser && (sessionUser.ROLEID === 1 || sessionUser.ROLEID === 2) && (
          <div
            className={"tab " + (activeTab === "draft" ? "active-tab" : "")}
            onClick={() => {
              setActiveTab("draft");
            }}
          >
            <div className="text">Drafts</div>
          </div>
        )}
        {sessionUser && (sessionUser.ROLEID === 1 || sessionUser.ROLEID === 2) && (
          <div
            className={"tab " + (activeTab === "new" ? "active-tab" : "")}
            onClick={() => {
              setActiveTab("new");
            }}
          >
            <div className="text">
              <div className="icon">
                <img src={iconAdd} alt="+" />
              </div>
              New Publication
            </div>
          </div>
        )}
      </div>
    );
  };

  const BuildSearchView = () => {
    if (activeTab === "all")
      return (
        <div className="search-view">
          <div className="al-container-50">
            <FormInput
              type="text"
              placeholder="Search publication"
              noLabel={true}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>
      );
    else return <></>;
  };

  const BuildTabViews = () => {
    if (activeTab === "all") {
      return BuildListPublications();
    } else if (activeTab === "draft") {
      return BuildListDraftPublications();
    } else if (activeTab === "new") {
      return BuildNewPublicationView();
    }
  };

  const BuildListDraftPublications = () => {
    if (!isGettingPublications) {
      if (!isErrorPublications) {
        if (publications.filter((doc) => doc.FLAG === "draft").length > 0) {
          return (
            <div className="list">
              {publications
                .filter((doc) => doc.FLAG === "draft")
                .map((doc) => (
                  <PublicationItem
                    title={doc.DOCTITLE}
                    date={doc.UPLOADDATE} //29-Jul-2021 08:00am
                    onClick={() => {
                      setSelectedPublication(doc);
                      setIsOpenViewPubDialog(true);
                    }}
                  />
                ))}
            </div>
          );
        } else {
          return <div className="list-empty al-muted-text">No drafts</div>;
        }
      } else {
        return (
          <div className="list-empty">
            <div className="flex-column">
              <div className="al-muted-text">Failed to load pubcalitions</div>
              <OutlineButton
                color="blue"
                text="Reload"
                onClick={getPublications}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="list-empty">
          <Loader size={60} />
        </div>
      );
    }
  };

  const BuildNewPublicationView = () => {
    return (
      <div className="list">
        <h3 className="al-mt-20">New Publication</h3>
        <div className="flex-container">
          <div className="al-container-50 padded">
            <FormInput
              label="Publication Title"
              placeholder=""
              type="text"
              maxLength="500"
              value={newPublicationTitle}
              onChange={(e) => {
                setNewPublicationTitle(e.target.value);
              }}
            />
          </div>

          <div className="query-attachments-list al-container-100 padded ">
            <div className="al-title">PDF File</div>
            <div className="al-content al-mt-10">
              <div className="al-left">
                {/* <ActionIcon toolTipMessage="attach" icon={iconAttachmentBlue} /> */}
                <Tooltip
                  content="Attach"
                  background="dodgerblue"
                  color="white"
                  border="white"
                  padding="10"
                  fontSize="0.8rem"
                  fadeDuration="100"
                >
                  <ActionIcon icon={iconAttachmentBlue} onClick={chooseFile} />
                </Tooltip>
              </div>
              <div className="al-right">
                {(() => {
                  if (!isRefreshingAttach)
                    if (attachmentFiles.length > 0) {
                      return attachmentFiles.map((file, index) => (
                        <QueryAttachment
                          title={file.name}
                          removable={true}
                          onRemove={() => {
                            removeFile(index);
                          }}
                        />
                      ));
                    } else {
                      return (
                        <div className="al-muted-text">No attachments</div>
                      );
                    }
                  else {
                    return (
                      <div className="al-muted-text">
                        <Loader size="40" />
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          </div>

          <input
            type="file"
            accept={".pdf"}
            multiple={false}
            hidden={true}
            onChange={handleFileChange}
            ref={hiddenFileInput}
          />

          <div className="al-container-50 padded">
            <FormButton
              text="Save as Draft"
              onClick={() => {
                verifyPostPublication({ isDraft: true });
              }}
              isProcessing={isSavingDraftPublication}
            />
          </div>

          <div className="al-container-50 padded">
            <FormButton
              text="Publish"
              onClick={() => {
                verifyPostPublication({ isDraft: false });
              }}
              isProcessing={isPostingPubcalition}
            />
          </div>
          <div className="white-space"></div>
        </div>
      </div>
    );
  };

  return (
    <div className="al-body">
      <SideNav activePage="publications" />
      <div className="al-main">
        <h1>Publications</h1>
        <div className="al-publications al-mt-20">
          {BuildTabs()}
          {BuildSearchView()}
          {BuildTabViews()}
        </div>
      </div>
      <Aside
        expandNotifications={() => {
          setIsOpenNotification(true);
        }}
      />

      <NotificationDialog
        onClose={() => {
          setIsOpenNotification(false);
        }}
        isOpen={isOPenNotification}
      />

      <ViewPublicationDialog
        document={selectedPublication}
        reloadParentList={() => {
          getPublications();
        }}
        setDocument={setSelectedPublication}
        isOpen={isOpenViewPubDialog}
        onClose={() => {
          setIsOpenViewPubDialog(false);
        }}
      />

      <AlertDialog
        type={alertDialogType}
        variation={alertDialogVariation}
        message={alertDialogMessage}
        isOpen={isOpenAlertDialog}
        isFixed={true}
        onClose={() => {
          setIsOpenAlertDialog(false);
        }}
        positiveButtonText={alertDialogPosBtnText}
        negativeButtonText={alertDialogNegBtnText}
      />
    </div>
  );
};

export default Publications;
