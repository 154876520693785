import React, { useState, useEffect } from "react";
import { getUsersApi } from "../../../static/ApiClient/UsersApi";
import Loader from "../../Loader/Loader";
import OutlineButton from "../../OutlineButton/OutlineButton";
import UserItem from "../../UserItem/UserItem";
import "./UserSelectDialog.css";
import { useSession } from "react-session-provider";

const UserSelectDialog = ({ isOPen, onClose, selectUser }) => {
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isErrorUsers, setIsErrorUsers] = useState(false);
  const [users, setUsers] = useState([]);

  const { store = {}, setKey } = useSession();
  const { sessionUserToken, sessionUser } = store;

  //use effetcs
  useEffect(() => {
    getUsers();
  }, []);

  //api calls
  const getUsers = async () => {
    setIsErrorUsers(false);
    setIsLoadingUsers(true);

    const res = await getUsersApi({ token: sessionUserToken });

    switch (res.message) {
      case "success":
        setUsers(res.data);
        break;
      case "error":
        setIsErrorUsers(true);
        break;
      default:
        setIsErrorUsers(true);
    }

    setIsLoadingUsers(false);
  };

  //view builders
  const BuildHeader = () => {
    return <div className="al-header">Select User</div>;
  };

  const BuildListView = () => {
    if (!isLoadingUsers) {
      if (!isErrorUsers) {
        if (
          users.filter(
            (user) =>
              user.SIGNUPSTATUS === "approved" &&
              user.DISABLED !== 1 &&
              user.ROLEID !== 1 &&
              user.ROLEID !== 2
          ).length > 0
        ) {
          return (
            <div className="user-list">
              {users
                .filter(
                  (user) =>
                    user.SIGNUPSTATUS === "approved" &&
                    user.DISABLED !== 1 &&
                    user.ROLEID !== 1 &&
                    user.ROLEID !== 2
                )
                .map((user, index) => (
                  <UserItem
                    fullname={user.FIRSTNAME + " " + user.LASTNAME}
                    role={user.ROLENAME ?? "N/A"}
                    status={user.DISABLED === 1 ? "inactive" : "active"}
                    onClick={() => {
                      selectUser(user);
                      onClose();
                    }}
                  />
                ))}
            </div>
          );
        } else {
          return (
            <div className="list-empty al-muted-text">No accepted users</div>
          );
        }
      } else {
        return (
          <div className="user-list-empty">
            <div className="flex-column">
              <div className="al-muted-text">Failed to load Users</div>
              <OutlineButton
                color="blue"
                text="Reload"
                onClick={() => {
                  getUsers();
                }}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="user-list-empty">
          <Loader size="60" />
        </div>
      );
    }
  };

  if (isOPen)
    return (
      <div className="al-dialog-full">
        <div className="al-dialog-gesture-detector"></div>
        <div className="al-select-user-dialog">
          {BuildHeader()}
          {BuildListView()}
          <button className="close-btn" onClick={onClose}>
            X
          </button>
        </div>
      </div>
    );
  else return <></>;
};

export default UserSelectDialog;
