import React, { useState, useRef } from "react";
import iconSendBlue from "../../img/icons/ic_send_blue.png";
import iconSendBlack from "../../img/icons/ic_send_black.png";
import iconAttachmentBlue from "../../img/icons/ic_attachment_black.png";
import Tooltip from "react-simple-tooltip";
import Loader from "../../components/Loader/Loader";
import ActionIcon from "../ActionIcon/ActionIcon";
import OutlineButton from "../OutlineButton/OutlineButton";
import QueryAttachment from "../QueryAttachment/QueryAttachment";
import moment from "moment";

import "./QueriesContainer.css";
import QueryItem from "../QueryItem/QueryItem";

const QueriesContainer = ({
  onClickAssignUser,
  queries,
  refreshList,
  isLoadingQueries,
  isErrorQueries,
  postQuery,
  showAlertDialog,
  setAttachmentFiles,
  attachmentFiles,
  setQueryContent,
  queryContent,
  isSendingQuery,
  isRefreshingAttach,
  setIsRefreshingAttach,
  deleteQuery,
  setQuotedQuery,
  quotedQuery,
  assignUser,
}) => {
  const hiddenFileInput = useRef(null);

  const validatePostQuery = () => {
    if (queryContent.trim().length > 0) {
      postQuery();
    }
  };

  /* Files */
  const handleFileChange = (e) => {
    setIsRefreshingAttach(true);

    if (typeof e.target.files[0] !== "undefined") {
      //check duplicate
      let isDuplicate = false;
      attachmentFiles.forEach((file, index) => {
        if (file.name === e.target.files[0].name) {
          showAlertDialog({
            msg: "File has already been chosen",
            type: "error",
            posText: "Ok",
            variation: "alert",
          });
          isDuplicate = true;
        }
      });

      if (!isDuplicate) {
        const fileUploaded = e.target.files[0];
        const extension = fileUploaded.name.split(".").pop();

        if (extension === "pdf") {
          var tmp = attachmentFiles;
          tmp.push(fileUploaded);
          setTimeout(() => {
            setAttachmentFiles(tmp);
          }, 500);
        } else
          showAlertDialog({
            msg: "Invalid file type, only PDF format is allowed",
            type: "error",
            posText: "Got it",
            variation: "alert",
          });
      }
    }
    setTimeout(() => {
      setIsRefreshingAttach(false);
    }, 3000);
  };

  const chooseFile = () => {
    hiddenFileInput.current.value = "";
    if (attachmentFiles.length < 2) {
      //maximum files
      hiddenFileInput.current.click();
    } else
      showAlertDialog({
        msg: "Maximum upload is 2 files",
        type: "error",
        posText: "Got it",
        variation: "alert",
      });
  };

  const removeFile = (index) => {
    setIsRefreshingAttach(true);
    var tmp = attachmentFiles;
    tmp.splice(index, 1);
    setAttachmentFiles(tmp);
    setTimeout(() => {
      setIsRefreshingAttach(false);
    }, 3000);
  };

  //view builders
  const BuildAttachmentPanel = () => {
    return (
      <div className="right">
        <Tooltip
          content="Attach"
          background="dodgerblue"
          color="white"
          border="white"
          padding="10"
          fontSize="0.8rem"
          fadeDuration="100"
        >
          <ActionIcon icon={iconAttachmentBlue} onClick={chooseFile} />
        </Tooltip>
        <input
          type="file"
          accept={".pdf"}
          multiple={false}
          hidden={true}
          onChange={handleFileChange}
          ref={hiddenFileInput}
        />

        <div className="attachments-list">
          {(() => {
            if (!isRefreshingAttach)
              if (attachmentFiles.length > 0) {
                return attachmentFiles.map((file, index) => (
                  <QueryAttachment
                    title={file.name}
                    removable={true}
                    onRemove={() => {
                      removeFile(index);
                    }}
                  />
                ));
              } else {
                return <div className="al-muted-text">No attachments</div>;
              }
            else {
              return (
                <div className="al-muted-text">
                  <Loader size="40" />
                </div>
              );
            }
          })()}
        </div>
      </div>
    );
  };

  const BuildInputPanel = () => {
    return (
      <div className="left">
        <textarea
          value={queryContent}
          onChange={(e) => {
            setQueryContent(e.target.value);
          }}
          placeholder="Type something..."
        ></textarea>

        <div
          className="send-icon"
          onClick={() => {
            if (!isSendingQuery) {
              validatePostQuery();
            }
          }}
        >
          <Tooltip
            content="Send"
            background="dodgerblue"
            color="white"
            border="white"
            padding="10"
            fontSize="0.8rem"
            fadeDuration="100"
            placement="top"
          >
            <img src={!isSendingQuery ? iconSendBlue : iconSendBlack} alt=">" />
          </Tooltip>
        </div>
      </div>
    );
  };

  const BuildQuotedMessage = () => {
    if (quotedQuery)
      return (
        <div className="top">
          <div className="quoted-user">{quotedQuery.FIRSTNAME} said:</div>
          <div className="quoted-content">
            <span className="q">&ldquo;</span>{" "}
            <span className="message">{quotedQuery.QUERYCONTENT}</span>
          </div>
          <div className="quoted-date">
            {moment(quotedQuery.DATEPOSTED).format("D-MMM-YYYY hh:mma")}
          </div>
          <button
            className="close-btn"
            onClick={() => {
              setQuotedQuery(null);
            }}
          >
            X
          </button>
        </div>
      );
    else return <></>;
  };

  const BuildQueriesList = () => {
    if (!isLoadingQueries)
      if (!isErrorQueries) {
        if (
          queries &&
          queries.filter(
            (query) => query.FLAG !== "deleted" && query.VISIBLE === 1
          ).length > 0
        ) {
          return (
            <div className="queries-list">
              {queries
                .filter(
                  (query) => query.FLAG !== "deleted" && query.VISIBLE === 1
                )
                .map((query) => (
                  <QueryItem
                    query={query}
                    onClickAssign={onClickAssignUser}
                    deleteQuery={deleteQuery}
                    setQuotedQuery={setQuotedQuery}
                    assignUser={assignUser}
                  />
                ))}
            </div>
          );
        } else {
          return <div className="queries-list-empty">No comments yet</div>;
        }
      } else {
        return (
          <div className="queries-list-empty">
            <div className="flex-column">
              <div className="al-muted-text">Failed to load comments</div>
              <OutlineButton color="blue" text="Reload" onClick={refreshList} />
            </div>
          </div>
        );
      }
    else
      return (
        <div className="queries-list-empty">
          <Loader size="50" />
        </div>
      );
  };

  return (
    <div className="al-queries-container">
      {BuildQueriesList()}
      <div className="new-query">
        {BuildQuotedMessage()}
        <div className="bottom">
          {BuildAttachmentPanel()}
          {BuildInputPanel()}
        </div>
      </div>
    </div>
  );
};

export default QueriesContainer;
