import React from "react";
import "./NotificationItem.css";
import moment from "moment";

const NotificationItem = ({
  isDismissable = true,
  notification,
  dismiss,
  theme = "",
}) => {
  return (
    <div className={"al-notification-item " + theme}>
      <div className="notification-content">
        {notification.NOTIFICATIONCONTENT}
      </div>
      <div className="notification-date">
        {moment(notification.DATENOTIFIED).format("D-MMM-YYYY hh:mma")}
      </div>
      {isDismissable && (
        <div className="overlay-container">
          <div className="btn-mark-read" onClick={dismiss} >Dismiss</div>
        </div>
      )}
    </div>
  );
};

export default NotificationItem;
