import React, { useState, useEffect } from "react";
import "./ViewPublicationDialog.css";
import { pdfjs, Document, Page } from "react-pdf";
import Loader from "../../Loader/Loader";
import AlertDialog from "../AlertDialog/AlertDialog";
import QueriesContainer from "../../QueriesContainer/QueriesContainer";
import OutlineButton from "../../OutlineButton/OutlineButton";
import UserSelectDialog from "../UserSelectDialog/UserSelectDialog";
import { BACKEND_URL } from "../../../Config";
import { updatePublicationApi } from "../../../static/ApiClient/PublicationsApi";
import { useSession } from "react-session-provider";
import {
  getDocumentQueriesApi,
  postQueryApi,
  updateQueryApi,
} from "../../../static/ApiClient/QueriesApi";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ViewPublicationDialog = ({
  isOpen,
  onClose,
  document,
  setDocument,
  reloadParentList,
}) => {
  const [activeTab, setActiveTab] = useState("view");
  const [isOPenUserSelectDialog, setIsOpenUserSelectDialog] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);
  const [alertDialogVariation, setAlertDialogVariation] = useState("confirm");
  const [alertDialogType, setAlertDialogType] = useState("warn");
  const [alertDialogMessage, setAlertDialogMessage] = useState("");
  const [alertDialogPosBtnText, setAlertDialogPosBtnText] = useState("");
  const [alertDialogNegBtnText, setAlertDialogNegBtnText] = useState("");

  const [nextAction, setNextAction] = useState("");
  const [isDeletingPublication, setIsDeletingPublication] = useState(false);
  const [isDraftingPublication, setIsDraftingPublication] = useState(false);

  const [queryContent, setQueryContent] = useState("");
  const [quotedQuery, setQuotedQuery] = useState(null);
  const [queryTypeId, setQueryTypeId] = useState(1);
  const [isSendingQuery, setIsSendingQuery] = useState(false);
  const [isRefreshingAttach, setIsRefreshingAttach] = useState(false);

  const [isLoadingQueries, setIsLoadingQueries] = useState(false);
  const [isErrorQueries, setIsErrorQueries] = useState(false);
  const [queries, setQueries] = useState([]);

  const [queryOnHold, setQueryOnHold] = useState(null);

  const [assignedToUser, setAssignedToUser] = useState(null);
  const [assignedQuery, setAssignedQuery] = useState(null);

  /* Files */
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  /* Files */

  const { store = {}, setKey } = useSession();
  const { sessionUser, sessionUserToken } = store;

  //useEffect
  useEffect(() => {
    if (document) {
      getPublicationQueries();
    }
  }, [document]);

  useEffect(() => {
    if (assignedToUser) assignQueryToUser();
  }, [assignedToUser]);

  //api calls
  const flagPublication = async (flg) => {
    setNextAction("");
    if (flg === "draft" || flg === "published") setIsDraftingPublication(true);
    else if (flg === "deleted") setIsDeletingPublication(true);

    const res = await updatePublicationApi({
      token: sessionUserToken,
      data: {
        DOCUMENTID: document.DOCUMENTID,
        FLAG: flg,
      },
    });

    switch (res.message) {
      case "success":
        document.FLAG = flg;
        setDocument(document);
        reloadParentList();
        showAlertDialog({
          msg: `Publication modified successfully`,
          type: "success",
          posText: "Ok",
          variation: "alert",
        });
        break;
      case "error":
        showAlertDialog({
          msg: `An error occurred`,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;

      default:
        showAlertDialog({
          msg: res.message,
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    if (flg === "draft" || flg === "published") setIsDraftingPublication(false);
    else if (flg === "deleted") setIsDeletingPublication(false);
  };

  const getPublicationQueries = async () => {
    setIsLoadingQueries(true);
    setQueries([]);
    const res = await getDocumentQueriesApi({
      token: sessionUserToken,
      id: document.DOCUMENTID,
    });
    console.log("queries returned ", res);
    switch (res.message) {
      case "success":
        setIsErrorQueries(false);
        setQueries(res.data);
        break;
      case "error":
        setIsErrorQueries(true);
        setQueries([]);
        break;
      default:
        setQueries([]);
    }

    setIsLoadingQueries(false);
  };

  const postQuery = async () => {
    setIsSendingQuery(true);
    var data = {
      ENQUIRYID: null,
      DOCUMENTID: document ? document.DOCUMENTID : null,
      USERID: sessionUser.ID,
      QUERYCONTENT: queryContent,
      QUERYTYPEID: queryTypeId,
      QUOTEDQUERYID: quotedQuery ? quotedQuery.QUERYID : null,
      FILES: attachmentFiles,
    };

    // alert("Data is " + JSON.stringify(data));

    const apiRes = await postQueryApi({ token: sessionUserToken, data: data });

    switch (apiRes.message) {
      case "success":
        setQueryContent("");
        setQuotedQuery(null);
        setQueryTypeId(1);
        setIsRefreshingAttach(true);
        setAttachmentFiles([]);
        getPublicationQueries();
        setTimeout(() => {
          setIsRefreshingAttach(false);
        }, 2000);

        break;
      default:
        console.log("***** error occurred", apiRes);
        showAlertDialog({
          msg: "An error occurred, failed to post",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }
    setIsSendingQuery(false);
  };

  const deleteQuery = async (query) => {
    var obj = {
      QUERYID: query.QUERYID,
      FLAG: "deleted",
    };

    const apiRes = await updateQueryApi({ token: sessionUserToken, data: obj });

    switch (apiRes.message) {
      case "success":
        setQueryOnHold(null);
        showAlertDialog({
          msg: "Query successfully deleted",
          type: "success",
          posText: "Ok",
          variation: "alert",
        });
        getPublicationQueries();
        break;
      case "error":
        showAlertDialog({
          msg: "An error occurred, failed to make changes",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
        break;
      default:
    }
  };

  const assignQueryToUser = async () => {
    alert(
      `assigning query ${JSON.stringify(queryOnHold)} to user ${JSON.stringify(
        assignedToUser
      )}`
    );
  };

  //other methods
  const handleClickAssignUser = (query) => {
    setQueryOnHold(query);
    setIsOpenUserSelectDialog(true);
  };

  const confirmDeleteQuery = (query) => {
    setNextAction("deleteQuery");
    setQueryOnHold(query);
    showAlertDialog({
      msg: "Are you sure you want to delete this entry?",
      type: "warn",
      posText: "Yes, Delete",
      negText: "No",
      variation: "confirm",
    });
  };

  //show dialog
  const showAlertDialog = ({
    msg = "",
    type = "",
    posText = "",
    negText = "",
    variation = "",
  }) => {
    setAlertDialogMessage(msg);
    setAlertDialogNegBtnText(negText);
    setAlertDialogPosBtnText(posText);
    setAlertDialogType(type);
    setAlertDialogVariation(variation);
    setIsOpenAlertDialog(true);
  };

  //pdf methods

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  //view builders

  const BuildLoadingView = () => {
    return (
      <div className="al-loading-view">
        <Loader size="50" />
      </div>
    );
  };

  const BuildPDFView = () => {
    return (
      <div className="al-pdf-wrapper">
        <Document
          file={`${BACKEND_URL}${document.DOCPATH}`}
          options={{
            workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`,
          }}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={BuildLoadingView()}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <div className="al-pdf-controls">
          <div className="text">
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </div>
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const BuildTabs = () => {
    return (
      <div className="al-tabs">
        <div
          className={"tab " + (activeTab === "view" ? "active-tab" : "")}
          onClick={() => {
            setActiveTab("view");
          }}
        >
          View
        </div>
        <div
          className={"tab " + (activeTab === "comments" ? "active-tab" : "")}
          onClick={() => {
            setActiveTab("comments");
          }}
        >
          Comments
        </div>
        {(sessionUser.ROLEID === 1 || sessionUser.ROLEID === 2) && (
          <div
            className={"tab " + (activeTab === "more" ? "active-tab" : "")}
            onClick={() => {
              setActiveTab("more");
            }}
          >
            More
          </div>
        )}
      </div>
    );
  };

  const BuildCommentsView = () => {
    return (
      <div className="al-comments-list">
        <QueriesContainer
          queries={queries}
          refreshList={getPublicationQueries}
          isLoadingQueries={isLoadingQueries}
          isErrorQueries={isErrorQueries}
          postQuery={postQuery}
          showAlertDialog={showAlertDialog}
          attachmentFiles={attachmentFiles}
          setAttachmentFiles={setAttachmentFiles}
          setQueryContent={setQueryContent}
          setQuotedQuery={setQuotedQuery}
          quotedQuery={quotedQuery}
          queryContent={queryContent}
          isSendingQuery={isSendingQuery}
          isRefreshingAttach={isRefreshingAttach}
          setIsRefreshingAttach={setIsRefreshingAttach}
          deleteQuery={confirmDeleteQuery}
          assignUser={handleClickAssignUser}
        />
      </div>
    );
  };

  const BuildMoreView = () => {
    return (
      <div className="flex-container padded">
        <div className="al-container-50 padded">
          <OutlineButton
            text="Delete"
            color="red"
            isProcessing={isDeletingPublication}
            onClick={() => {
              setNextAction("deletePublication");
              setAlertDialogMessage(
                "Are you sure you want to delete this publication?"
              );
              setAlertDialogNegBtnText("No");
              setAlertDialogPosBtnText("Yes, Delete");
              setAlertDialogType("error");
              setAlertDialogVariation("confirm");
              setIsOpenAlertDialog(true);
            }}
            width="100%"
          />
        </div>
        {document.FLAG === "published" && (
          <div className="al-container-50 padded">
            <OutlineButton
              text="Pull to Draft"
              isProcessing={isDraftingPublication}
              onClick={() => {
                setNextAction("pullToDraft");
                setAlertDialogMessage(
                  "Are you sure you want to pull this publication back to drafts?"
                );
                setAlertDialogNegBtnText("No");
                setAlertDialogPosBtnText("Yes, I am");
                setAlertDialogType("warn");
                setAlertDialogVariation("confirm");
                setIsOpenAlertDialog(true);
              }}
              width="100%"
            />
          </div>
        )}

        {document.FLAG === "draft" && (
          <div className="al-container-50 padded">
            <OutlineButton
              text="Publish"
              isProcessing={isDraftingPublication}
              color="green"
              onClick={() => {
                flagPublication("published");
              }}
              width="100%"
            />
          </div>
        )}
      </div>
    );
  };

  const BuildTabViews = () => {
    switch (activeTab) {
      case "view":
        return BuildPDFView();
      case "comments":
        return BuildCommentsView();
      case "more":
        return BuildMoreView();
      default:
        return BuildPDFView();
    }
  };

  if (isOpen)
    return (
      <>
        <div className="al-dialog-full">
          <div className="al-dialog-gesture-detector" onClick={onClose}></div>
          <div className="al-view-publication-dialog">
            {BuildTabs()}
            {BuildTabViews()}
            <button className="close-btn" onClick={onClose}>
              X
            </button>
          </div>
        </div>

        <UserSelectDialog
          selectUser={setAssignedToUser}
          isOPen={isOPenUserSelectDialog}
          onClose={() => {
            setIsOpenUserSelectDialog(false);
          }}
        />

        <AlertDialog
          type={alertDialogType}
          variation={alertDialogVariation}
          message={alertDialogMessage}
          isOpen={isOpenAlertDialog}
          positiveButtonMethod={() => {
            if (nextAction === "deletePublication") flagPublication("deleted");
            else if (nextAction === "pullToDraft") flagPublication("draft");
            else if (nextAction === "deleteQuery") deleteQuery(queryOnHold);
            setIsOpenAlertDialog(false);
          }}
          onClose={() => {
            setIsOpenAlertDialog(false);
          }}
          positiveButtonText={alertDialogPosBtnText}
          negativeButtonText={alertDialogNegBtnText}
        />
      </>
    );
  else return <></>;
};

export default ViewPublicationDialog;
