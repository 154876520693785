import { React, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSession } from "react-session-provider";
import axios from "axios";

import iconLogo from "../../img/dib-logo.png";
import iconPdfBlue from "../../img/icons/ic_pdf_blue.png";
import iconPdfBlack from "../../img/icons/ic_pdf_black.png";
import iconUsersBlue from "../../img/icons/ic_users_blue.png";
import iconUsersBlack from "../../img/icons/ic_users_black.png";
import iconUserBlue from "../../img/icons/ic_user_blue.png";
import iconUserBlack from "../../img/icons/ic_user_black.png";
import iconSignoutBlue from "../../img/icons/ic_signout_blue.png";
import iconSignoutBlack from "../../img/icons/ic_signout_black.png";
import iconPublicationBlue from "../../img/icons/ic_publication_blue.png";
import iconPublicationBlack from "../../img/icons/ic_publication_black.png";
import "./SideNav.css";
import { signOutApi } from "../../static/ApiClient/UsersApi";

const SideNav = ({ activePage }) => {
  const { store = {}, setKey, deleteKey } = useSession();
  const { sessionUser, sessionUserToken } = store;

  const history = useHistory();

  const handleSignOut = async (e) => {
    e.preventDefault();

    const apiRes = await signOutApi(sessionUserToken);
    if (apiRes.message === "success") {
      console.log("signout successful");
    } else {
      console.log(apiRes.message);
    }
    //clear session
    deleteKey("sessionUser");
    deleteKey("sessionUserToken");

    history.push("/");
  };

  return (
    <div className="al-nav-bar">
      <div className="al-nav-logo">
        <img src={iconLogo} alt="DIB" />
      </div>

      <div className="al-nav-items">
        <a
          href="/publications"
          className={activePage === "publications" ? "al-active-link" : ""}
        >
          <div className="al-icon">
            <img
              src={activePage !== "publications" ? iconPdfBlack : iconPdfBlue}
              alt="i"
            />
          </div>
          <div className={"al-text "}>Publications</div>
        </a>
        {sessionUser && (sessionUser.ROLEID === 1 || sessionUser.ROLEID === 2) && (
          <a
            href="/users"
            className={activePage === "users" ? "al-active-link" : ""}
          >
            <div className="al-icon">
              <img
                src={activePage !== "users" ? iconUsersBlack : iconUsersBlue}
                alt="i"
              />
            </div>
            <div className={"al-text "}>Users</div>
          </a>
        )}
        <a
          href="/enquiries"
          className={activePage === "enquiries" ? "al-active-link" : ""}
        >
          <div className="al-icon">
            <img
              src={
                activePage !== "enquiries"
                  ? iconPublicationBlack
                  : iconPublicationBlue
              }
              alt="i"
            />
          </div>
          <div className={"al-text "}>Enquiries</div>
        </a>
        <a
          href="/profile"
          className={activePage === "profile" ? "al-active-link" : ""}
        >
          <div className="al-icon">
            <img
              src={activePage !== "profile" ? iconUserBlack : iconUserBlue}
              alt="i"
            />
          </div>
          <div className={"al-text "}>Me</div>
        </a>
        <a href="/" onClick={handleSignOut}>
          <div className="al-icon">
            <img src={iconSignoutBlack} alt="i" />
          </div>
          <div className="al-text">Sign out</div>
        </a>
      </div>
    </div>
  );
};

export default SideNav;
