import { React, useEffect, useState, useRef } from "react";

import Aside from "../../components/Aside/Aside";
import NotificationDialog from "../../components/dialogs/NotificationDialog/NotificationDialog";
import SideNav from "../../components/SideNav/SideNav";
import iconAdd from "../../img/icons/ic_add_blue.png";
import FormInput from "../../components/Inputs/FormInput/FormInput";
import FormButton from "../../components/FormButton/FormButton";
import Loader from "../../components/Loader/Loader";
import iconAttachmentBlue from "../../img/icons/ic_attachment_black.png";
import Tooltip from "react-simple-tooltip";
import OutlineButton from "../../components/OutlineButton/OutlineButton";
import AlertDialog from "../../components/dialogs/AlertDialog/AlertDialog";
import ActionIcon from "../../components/ActionIcon/ActionIcon";
import QueryAttachment from "../../components/QueryAttachment/QueryAttachment";
import { useSession } from "react-session-provider";
// import Tooltip from "react-simple-tooltip";

import "./Enquiries.css";
import EnquiryItem from "../../components/EnquiryItem/EnquiryItem";
import ForumDialog from "../../components/dialogs/ForumDialog/ForumDialog";
import {
  getAllEnquiriesApi,
  postEnquiryApi,
} from "../../static/ApiClient/EnquiriesApi";
import { getQueryTypesApi } from "../../static/ApiClient/QueriesApi";
import {
  validateFields,
  validateSelect,
} from "../../static/Validator/validator";
import { useHistory } from "react-router-dom";
import { signOutApi } from "../../static/ApiClient/UsersApi";

const Enquiries = () => {
  const [isOPenNotification, setIsOpenNotification] = useState(false);
  const [activeTab, setActiveTab] = useState("public");

  const [isOpenForumDialog, setIsOpenForumDialog] = useState(false);

  const [isLoadingEnquiries, setIsLoadingEnquiries] = useState(false);
  const [enquiries, setEnquires] = useState([]);
  const [isErrorEnqruiries, setIsErrorEnquiries] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [queryTypes, setQueryTypes] = useState([]);

  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);
  const [alertDialogVariation, setAlertDialogVariation] = useState("confirm");
  const [alertDialogType, setAlertDialogType] = useState("warn");
  const [alertDialogMessage, setAlertDialogMessage] = useState("");
  const [alertDialogPosBtnText, setAlertDialogPosBtnText] = useState("");
  const [alertDialogNegBtnText, setAlertDialogNegBtnText] = useState("");

  const [enquiryTitle, setEnquiryTitle] = useState("");
  const [enquiryContent, setEnquiryContent] = useState("");
  const [enquiryAccessibility, setEnquiryAccessibility] = useState("-1");
  const [enquiryQueryType, setEnquiryQueryType] = useState("-1");
  const [isSendingEnquiry, setIsSendingEnquiry] = useState(false);

  /* Files */
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const hiddenFileInput = useRef(null);
  const [isRefreshingAttach, setIsRefreshingAttach] = useState(false);
  /* Files */

  const queryPrivacy = [
    { display: "Private", value: 0 },
    { display: "Pubilc", value: 1 },
  ];

  const { store = {}, setKey, deleteKey } = useSession();
  const { sessionUser, sessionUserToken } = store;
  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredListEnquiries, setFilteredListEnquiries] = useState([]);

  //useEffects

  //page security
  useEffect(() => {
    if (sessionUser) {
      // if (sessionUser.ROLEID === 1 || sessionUser.ROLEID === 2) {
      //   console.log("session ok");
      // } else {
      //   history.push("/error");
      // }
    } else {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    getQueryTypes();
    getEnquiries();
  }, []);

  useEffect(() => {
    if (enquiries.length > 0) searchEnquiries();
  }, [searchQuery]);

  //api calls

  const getEnquiries = async () => {
    setIsLoadingEnquiries(true);

    const res = await getAllEnquiriesApi({ token: sessionUserToken });

    switch (res.message) {
      case "success":
        setIsErrorEnquiries(false);
        setEnquires(res.data);
        break;
      case "session expired":
        setIsErrorEnquiries(true);
        const apiRes = await signOutApi(sessionUserToken);
        if (apiRes.message === "success") {
          console.log("signout successful");
        } else {
          console.log(apiRes.message);
        }
        //clear session
        deleteKey("sessionUser");
        deleteKey("sessionUserToken");
        history.push("/");
        break;
      case "error":
        setIsErrorEnquiries(true);
        break;
      default:
        setIsErrorEnquiries(true);
        break;
    }
    setIsLoadingEnquiries(false);
  };

  const getQueryTypes = async () => {
    const res = await getQueryTypesApi();
    switch (res.message) {
      case "success":
        var tmp = [];
        res.data.forEach((queryType) => {
          tmp.push({
            display: queryType.QUERYTYPE,
            value: queryType.QUERYTYPEID,
          });
        });
        setQueryTypes(tmp);
        break;
      case "error":
        break;
      default:
        break;
    }
  };

  const postEnquiry = async () => {
    setIsSendingEnquiry(true);

    var data = {
      BANKID: sessionUser.BANKID,
      ACCESSIBILITY: parseInt(enquiryAccessibility),
      USERID: sessionUser.ID,
      ENQUIRYCONTENT: enquiryContent,
      ENQUIRYTITLE: enquiryTitle,
      QUERYTYPEID: enquiryQueryType,
      FILES: attachmentFiles,
    };

    const res = await postEnquiryApi({ token: sessionUserToken, data: data });

    switch (res.message) {
      case "success":
        showAlertDialog({
          msg: "Query posted successfully",
          type: "success",
          posText: "Ok",
          variation: "alert",
        });
        getEnquiries();

        setEnquiryTitle("");
        setEnquiryContent("");
        setEnquiryQueryType("-1");
        setEnquiryAccessibility("-1");

        setIsRefreshingAttach(true);
        setAttachmentFiles([]);
        setTimeout(() => {
          setIsRefreshingAttach(false);
        }, 2000);
        break;
      default:
        showAlertDialog({
          msg: "An error occurred, failed to post query",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    }

    setIsSendingEnquiry(false);
  };

  //other methods
  const validatePostEnquiry = () => {
    if (validateFields([enquiryTitle])) {
      if (validateFields([enquiryContent])) {
        if (validateSelect(enquiryQueryType)) {
          if (validateSelect(enquiryAccessibility)) {
            postEnquiry();
          } else {
            showAlertDialog({
              msg: "Choose a query privacy",
              type: "error",
              posText: "Ok",
              variation: "alert",
            });
          }
        } else {
          showAlertDialog({
            msg: "Chose a query type",
            type: "error",
            posText: "Ok",
            variation: "alert",
          });
        }
      } else
        showAlertDialog({
          msg: "Provide query details",
          type: "error",
          posText: "Ok",
          variation: "alert",
        });
    } else
      showAlertDialog({
        msg: "Provide query title",
        type: "error",
        posText: "Ok",
        variation: "alert",
      });
  };

  const searchEnquiries = () => {
    if (searchQuery.trim() !== "") {
      var temp = [];
      if (activeTab === "public") {
        temp = enquiries
          .filter(
            (enq) => enq.ACCESSIBILITY === 1 && (enq.FLAG ?? "") !== "deleted"
          )
          .filter(
            (enq) =>
              (enq.ENQUIRYTITLE ?? "")
                .toLowerCase()
                .includes(searchQuery.trim().toLowerCase()) ||
              (enq.ENQUIRYCONTENT ?? "")
                .toLowerCase()
                .includes(searchQuery.trim().toLowerCase())
          );
      } else if (activeTab === "private") {
        temp = enquiries
          .filter(
            (enq) => enq.ACCESSIBILITY === 0 && (enq.FLAG ?? "") !== "deleted"
          )
          .filter(
            (enq) =>
              (enq.ENQUIRYTITLE ?? "")
                .toLowerCase()
                .includes(searchQuery.trim().toLowerCase()) ||
              (enq.ENQUIRYCONTENT ?? "")
                .toLowerCase()
                .includes(searchQuery.trim().toLowerCase())
          );
      }
      setFilteredListEnquiries(temp);
    } else {
      setFilteredListEnquiries([]);
    }
  };

  /* Files */
  const handleFileChange = (e) => {
    setIsRefreshingAttach(true);

    if (typeof e.target.files[0] !== "undefined") {
      //check duplicate
      let isDuplicate = false;
      attachmentFiles.forEach((file, index) => {
        if (file.name === e.target.files[0].name) {
          showAlertDialog({
            msg: "File has already been chosen",
            type: "error",
            posText: "Ok",
            variation: "alert",
          });
          isDuplicate = true;
        }
      });

      if (!isDuplicate) {
        const fileUploaded = e.target.files[0];
        const extension = fileUploaded.name.split(".").pop();

        if (extension === "pdf") {
          var tmp = attachmentFiles;
          tmp.push(fileUploaded);
          setTimeout(() => {
            setAttachmentFiles(tmp);
          }, 500);
        } else
          showAlertDialog({
            msg: "Invalid file type, only PDF format is allowed",
            type: "error",
            posText: "Got it",
            variation: "alert",
          });
      }
    }
    setTimeout(() => {
      setIsRefreshingAttach(false);
    }, 3000);
  };

  const showAlertDialog = ({
    msg = "",
    type = "",
    posText = "",
    negText = "",
    variation = "",
    isFixed = false,
  }) => {
    setAlertDialogMessage(msg);
    setAlertDialogNegBtnText(negText);
    setAlertDialogPosBtnText(posText);
    setAlertDialogType(type);
    setAlertDialogVariation(variation);
    setIsOpenAlertDialog(true);
  };

  const chooseFile = () => {
    hiddenFileInput.current.value = "";
    if (attachmentFiles.length < 3) {
      //maximum files
      hiddenFileInput.current.click();
    } else
      showAlertDialog({
        msg: "Maximum upload is 3 files",
        type: "error",
        posText: "Got it",
        variation: "alert",
      });
  };

  const removeFile = (index) => {
    setIsRefreshingAttach(true);
    var tmp = attachmentFiles;
    tmp.splice(index, 1);
    setAttachmentFiles(tmp);
    setTimeout(() => {
      setIsRefreshingAttach(false);
    }, 3000);
  };
  /* Files */

  //view builders

  const BuildSearchView = () => {
    if (activeTab === "private" || activeTab === "public") {
      return (
        <div className="search-view">
          <div className="al-container-50">
            <FormInput
              type="text"
              placeholder="Find enquiry by title or words in the description..."
              noLabel={true}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>
      );
    } else return <></>;
  };

  const BuildTabViews = () => {
    switch (activeTab) {
      case "public":
        return BuildTabViewPublicEquiries();
      case "private":
        return BuildTabViewPrivateEnquiries();
      case "new":
        return BuildTabViewNewEnquiry();
      default:
        return <></>;
    }
  };

  const BuildTabViewPublicEquiries = () => {
    if (!isLoadingEnquiries) {
      if (!isErrorEnqruiries) {
        if (searchQuery !== "" && activeTab === "public") {
          //seach parameters exist
          if (filteredListEnquiries.length > 0) {
            return (
              <div className="list">
                {filteredListEnquiries.map((enq) => (
                  <EnquiryItem
                    onClick={() => {
                      setSelectedEnquiry(enq);
                      setIsOpenForumDialog(true);
                    }}
                    title={enq.ENQUIRYTITLE}
                    date={enq.DATEPOSTED}
                  />
                ))}
              </div>
            );
          } else {
            return (
              <div className="list-empty al-muted-text">Nothing found!</div>
            );
          }
        } else {
          if (
            enquiries.filter(
              (enq) => enq.ACCESSIBILITY === 1 && (enq.FLAG ?? "") !== "deleted"
            ).length > 0
          ) {
            return (
              <div className="list">
                {enquiries
                  .filter(
                    (enq) =>
                      enq.ACCESSIBILITY === 1 && (enq.FLAG ?? "") !== "deleted"
                  )
                  .map((enq) => (
                    <EnquiryItem
                      onClick={() => {
                        setSelectedEnquiry(enq);
                        setIsOpenForumDialog(true);
                      }}
                      title={enq.ENQUIRYTITLE}
                      date={enq.DATEPOSTED}
                    />
                  ))}
              </div>
            );
          } else {
            return (
              <div className="list-empty al-muted-text">
                No public enquiries, click on private enquiries to see your
                private enquiries
              </div>
            );
          }
        }
      } else {
        return (
          <div className="list-empty">
            <div className="flex-column">
              <div className="al-muted-text">Failed to load enquiries</div>
              <OutlineButton
                color="blue"
                text="Reload"
                onClick={getEnquiries}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="list-empty">
          <Loader size="60" />
        </div>
      );
    }
  };

  const BuildTabViewPrivateEnquiries = () => {
    if (!isLoadingEnquiries) {
      if (!isErrorEnqruiries) {
        if (searchQuery !== "" && activeTab === "private") {
          //seach parameters exist
          if (filteredListEnquiries.length > 0) {
            return (
              <div className="list">
                {filteredListEnquiries.map((enq) => (
                  <EnquiryItem
                    onClick={() => {
                      setSelectedEnquiry(enq);
                      setIsOpenForumDialog(true);
                    }}
                    title={enq.ENQUIRYTITLE}
                    date={enq.DATEPOSTED}
                  />
                ))}
              </div>
            );
          } else {
            return (
              <div className="list-empty al-muted-text">Nothing found!</div>
            );
          }
        } else {
          if (sessionUser.ROLEID === 1 || sessionUser.ROLEID === 2) {
            //is administrator or DIB
            if (
              enquiries.filter(
                (enq) =>
                  enq.ACCESSIBILITY === 0 && (enq.FLAG ?? "") !== "deleted"
              ).length > 0
            ) {
              return (
                <div className="list">
                  {enquiries
                    .filter(
                      (enq) =>
                        enq.ACCESSIBILITY === 0 &&
                        (enq.FLAG ?? "") !== "deleted"
                    )
                    .map((enq) => (
                      <EnquiryItem
                        onClick={() => {
                          setSelectedEnquiry(enq);
                          setIsOpenForumDialog(true);
                        }}
                        title={enq.ENQUIRYTITLE}
                        date={enq.DATEPOSTED}
                      />
                    ))}
                </div>
              );
            } else {
              return (
                <div className="list-empty al-muted-text">
                  No private enquiries by any user
                </div>
              );
            }
          } else {
            //not admin or dib
            if (
              enquiries.filter(
                (enq) =>
                  enq.ACCESSIBILITY === 0 && enq.ENQUIREDBY === sessionUser.ID
              ).length > 0
            ) {
              return (
                <div className="list">
                  {enquiries
                    .filter(
                      (enq) =>
                        enq.ACCESSIBILITY === 0 &&
                        enq.ENQUIREDBY === sessionUser.ID
                    )
                    .map((enq) => (
                      <EnquiryItem
                        onClick={() => {
                          setSelectedEnquiry(enq);
                          setIsOpenForumDialog(true);
                        }}
                        title={enq.ENQUIRYTITLE}
                        date={enq.DATEPOSTED}
                      />
                    ))}
                </div>
              );
            } else {
              return (
                <div className="list-empty al-muted-text">
                  No private enquiries, click on new enquiry to post a new one
                  or go to public enquires to see what others have enquired.
                </div>
              );
            }
          }
        }
      } else {
        return (
          <div className="list-empty">
            <div className="flex-column">
              <div className="al-muted-text">Failed to load enquiries</div>
              <OutlineButton
                color="blue"
                text="Reload"
                onClick={getEnquiries}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="list-empty">
          <Loader size="60" />
        </div>
      );
    }
  };

  const BuildTabViewNewEnquiry = () => {
    return (
      <div className="list">
        <h3 className="al-mt-20"> New Query</h3>
        <div className="flex-container">
          <div className="al-container-100 padded">
            <FormInput
              label="Query Title"
              placeholder="100 characters maximum"
              type="text"
              maxLength="100"
              name="QUERYTITLE"
              value={enquiryTitle}
              onChange={(e) => {
                setEnquiryTitle(e.target.value);
              }}
            />
          </div>
          <div className="al-container-50 padded">
            <FormInput
              type="select"
              options={queryTypes}
              label="Query Type"
              placeholder="Choose one"
              name="QUERYTYPEID"
              value={enquiryQueryType}
              onChange={(e) => {
                setEnquiryQueryType(e.target.value);
              }}
            />
          </div>

          <div className="al-container-50 padded">
            <FormInput
              type="select"
              options={queryPrivacy}
              label="Query Privacy"
              placeholder="Choose one"
              name="ACCESSIBILITY"
              value={enquiryAccessibility}
              onChange={(e) => {
                setEnquiryAccessibility(e.target.value);
              }}
            />
          </div>

          <div className="al-container-100 padded">
            <FormInput
              label="Your query"
              placeholder="Write your query ..."
              type="textarea"
              name="ENQUIRYCONTENT"
              value={enquiryContent}
              onChange={(e) => {
                setEnquiryContent(e.target.value);
              }}
            />
          </div>
          <div className="query-attachments-list al-container-100 padded ">
            <div className="al-title">Attachments</div>
            <div className="al-content al-mt-10">
              <div className="al-left">
                {/* <ActionIcon toolTipMessage="attach" icon={iconAttachmentBlue} /> */}
                <Tooltip
                  content="Attach"
                  background="dodgerblue"
                  color="white"
                  border="white"
                  padding="10"
                  fontSize="0.8rem"
                  fadeDuration="100"
                >
                  <ActionIcon icon={iconAttachmentBlue} onClick={chooseFile} />
                </Tooltip>
              </div>
              <div className="al-right">
                {(() => {
                  if (!isRefreshingAttach)
                    if (attachmentFiles.length > 0) {
                      return attachmentFiles.map((file, index) => (
                        <QueryAttachment
                          title={file.name}
                          removable={true}
                          onRemove={() => {
                            removeFile(index);
                          }}
                        />
                      ));
                    } else {
                      return (
                        <div className="al-muted-text">No attachments</div>
                      );
                    }
                  else {
                    return (
                      <div className="al-muted-text">
                        <Loader size="40" />
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
          <input
            type="file"
            accept={".pdf"}
            multiple={false}
            hidden={true}
            onChange={handleFileChange}
            ref={hiddenFileInput}
          />
          <div className="al-container-50 padded">
            <FormButton
              text="Send Query"
              isProcessing={isSendingEnquiry}
              onClick={validatePostEnquiry}
            />
          </div>
          <div className="white-space"></div>
        </div>
      </div>
    );
  };

  const BuildTabs = () => {
    return (
      <div className="tabs">
        <div
          className={"tab " + (activeTab === "public" ? "active-tab" : "")}
          onClick={() => {
            setSearchQuery("");
            setFilteredListEnquiries([]);
            setActiveTab("public");
          }}
        >
          <div className="text">Public Enquiries</div>
          {/* <div className="count bg-green">120</div> */}
        </div>
        <div
          className={"tab " + (activeTab === "private" ? "active-tab" : "")}
          onClick={() => {
            setSearchQuery("");
            setFilteredListEnquiries([]);
            setActiveTab("private");
          }}
        >
          <div className="text">Private Enquiries</div>
          {/* <div className="count bg-red">5</div> */}
        </div>
        <div
          className={"tab " + (activeTab === "new" ? "active-tab" : "")}
          onClick={() => {
            setActiveTab("new");
          }}
        >
          <div className="text">
            <div className="icon">
              <img src={iconAdd} alt="+" />
            </div>
            New Query
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="al-body">
      <SideNav activePage="enquiries" />
      <div className="al-main">
        <h1>Enquiries</h1>
        <div className="al-enquiries-tab-view al-mt-20">
          {BuildTabs()}
          {BuildSearchView()}
          {BuildTabViews()}
        </div>
      </div>
      <Aside
        expandNotifications={() => {
          setIsOpenNotification(true);
        }}
      />

      <NotificationDialog
        onClose={() => {
          setIsOpenNotification(false);
        }}
        isOpen={isOPenNotification}
      />

      <ForumDialog
        isOpen={isOpenForumDialog}
        enquiry={selectedEnquiry}
        onClose={() => {
          setSelectedEnquiry(null);
          setIsOpenForumDialog(false);
        }}
      />

      <AlertDialog
        type={alertDialogType}
        variation={alertDialogVariation}
        message={alertDialogMessage}
        isOpen={isOpenAlertDialog}
        isFixed={true}
        onClose={() => {
          setIsOpenAlertDialog(false);
        }}
        positiveButtonText={alertDialogPosBtnText}
        negativeButtonText={alertDialogNegBtnText}
      />
    </div>
  );
};

export default Enquiries;
